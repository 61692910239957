import React, { Component } from 'react';
import Topo from '../../containers/Topo';
import MenuSticky from '../../components/menuSticky';
import Plataforma from '../../containers/Plataforma';
import Produtores from '../../containers/Produtores';
import Artista from '../../containers/Artistas';
import TvOoppah from '../../containers/Tv';
import Depoimentos from '../../containers/Depoimentos';
import Rodape from '../../containers/Rodape';
import RegisterForm from "../../components/RegisterForm/index";
import Quemsomos from '../../containers/Quemsomos';
import FloatActionButton from '../../components/FloatActionButton';
import LogoWp from '../../assets/LogoWP.png';

import Packages from '../../components/Packages/index'
import ActCodeAndRegistration from '../../containers/ActCodeAndRegistration';
import SuccessCases from '../../containers/SuccessCases';
import Players from '../../containers/Players';
import Club from '../../containers/Club';
import PackagesPremium from '../../components/PackagesPremium';
// require('dotenv').config();

class App extends Component {

	constructor(){
		super();
		this.state = {
			menuStickyScroll: false,
			menuStickyHeight: 'auto'
		};
	}

	componentDidMount(){
		const stickyMenuOffset = document.querySelector('#topBanner');
		const stickyMenu = document.getElementById('stickyMenu');
		const stickyStyle = window.getComputedStyle(stickyMenu);

		this.setState({
			menuStickyHeight: stickyStyle.getPropertyValue('height')
		});

		document.addEventListener('scroll', () => {
			if (stickyMenuOffset.clientHeight <= window.scrollY) {
                stickyMenu.classList.add('is-sticky');
			}else{
                stickyMenu.classList.remove('is-sticky');
			}
		});
	}

	render() {
		return (
			<div>
				<Topo />
				<MenuSticky sticky={false} stickyHeight={this.state.menuStickyHeight}/>
				<Produtores />
				<SuccessCases />
				<Depoimentos />
				<Players />
				<Quemsomos />
				{/*<Packages />*/}
                <PackagesPremium/>
				{/*<ActCodeAndRegistration />*/}
				{/* <RegisterForm />*/}
				<Club/>
				{/*<TvOoppah />*/}
				<Rodape />
				<FloatActionButton linkTo = "https://wa.me/5521994765872?text=Ol%C3%A1,%20estou%20com%20problemas.">
					<img src = {LogoWp} />
				</FloatActionButton>
			</div>
		);
	}
}

export default App;
	