import React from 'react';
import { Container, Declaration, Name, Photo, Rating, Star } from './style';

import fullStar from '../../assets/estrela_dourada.svg'
import halfStar from '../../assets/estrela_dourada_meia.svg';

const CardArtist = ({artist}) =>{



    return (
        <Container>
            <Photo src = {artist.avatar} />

            <Name>
                {artist.name}
            </Name>

            <Rating>
                <Star src= {fullStar} />
                <Star src= {fullStar} />
                <Star src= {fullStar} />
                <Star src= {fullStar} />
                <Star src= {fullStar} />
            </Rating>

            <Declaration>
                {artist.declaration}
            </Declaration>

        </Container>

    );

}

export default CardArtist;