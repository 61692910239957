import React, { useState } from 'react';

import { Etapa, Footer, PlanTerms, SelectedPlan} from './style';

const ActCodeFooter = ({currentState, setCurrentState, planName, coupon}) => {
    return (
        <Footer>
            <Etapa>
                <div className= {currentState === 2 ? 'numero ativo': 'numero'} onClick = {() => {setCurrentState(2)}}>1</div>
            </Etapa>
            <SelectedPlan>
                <div className="plano-selecionado">
                    <div className="topo-titulo">Plano selecionado</div>
                    <div className="destaque">{planName}</div>
                </div>
            </SelectedPlan>
            <SelectedPlan>
                <div className="topo-titulo">Cupom de desconto</div>
                <div className="destaque">{coupon.code}</div>
            </SelectedPlan>
        </Footer>
    );
}

export default ActCodeFooter;