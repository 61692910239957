import React, { useRef, useState } from "react";
import { Container, Content, LinkButton, RegisterButton } from "./style";
import { useClickedOutside } from "../../hooks/useClickedOutside";
import HamburguerMenu from "../../assets/threeBar.svg";
import Icon from "../Icon";
const RegisterDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const openDrop = () => setIsOpen(true);
  const closeDrop = () => setIsOpen(false);
  useClickedOutside(dropdownRef, closeDrop, isOpen);
  const linkUrl = () => {
    let location = window.location.pathname;
    return location === "/" ? "#packages" : process.env.REACT_APP_HOME + "#packages";
  };
  return (
    <Container ref={dropdownRef}>
      <RegisterButton onClick={openDrop}>
        Cadastre-se
        <Icon src={HamburguerMenu} />
      </RegisterButton>
      {isOpen && (
        <Content>
          <LinkButton href={linkUrl("plans")}>
            <span>Cadastro Artista</span>
          </LinkButton>
          <LinkButton href={process.env.REACT_APP_PRODUCER_WEB + '/producer/register'} target="_blank">
            <span>Cadastro Produtor</span>
          </LinkButton>
          <LinkButton  
            href="https://wa.me/5521994765872?
            text=Oi%20Ooppah!%20Gostaria%20de%20cadastrar%20minha%20agencia%20na%20plataforma.%20Como%20faço?" 
            target="_blank">
            <span>Cadastro Agência</span>
          </LinkButton>
        </Content>
      )}
    </Container>
  );
};
export default RegisterDropdown;