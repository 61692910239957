import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    justify-content:center;
    width:100%;
    
    @media(min-width: 1080px){
        margin-top: 60px;
        margin-bottom: 60px;
        padding-left: 30px;
        padding-right: 30px;
    }

    


`;