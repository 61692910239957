import styled, { css } from 'styled-components';

export const Container = styled.div`
    border-bottom: 2px solid #ffffff;
    border-top: 2px solid #ffffff;
    position: relative;
    height: 18px;
    
    
    cursor: pointer;
    width: 25px;
    @media (min-width: 1080px) {
        display: none;
    }
    &:after {
        background-color: #ffffff;
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        top: 6px;
        left: 0;
    }
    ${props => props.dark && css`
        border-color: #000;
        &:after {
            background: #000;
        }
    `}
`;