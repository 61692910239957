import React, { Component } from 'react'
import {Link} from 'react-router-dom';

// Style
import './style.css'

// Images e Assets
import Nav, {NavItem} from "../Nav";
import BurgerMenu from "../BurgerMenu";
import Logo from "../Logo";
import LogoButton from "../LogoButton";

import logoStore from './../../assets/logoStore.png'

export default class MenuSticky extends Component {

    BASE_URL = process.env.REACT_APP_MONOLITICO_BASE;
    PROFILE_WEB = process.env.REACT_APP_PROFILE_WEB;
    PRODUCER_WEB = process.env.REACT_APP_PRODUCER_WEB;

    constructor(){
        super();
        this.state = {
            dropdownmenu: false,
            mainMenu: false
        }
    }

    toggleDropdown = () => {
        this.setState({dropdownmenu: !this.state.dropdownmenu})
    };
    toggleMainMenu = () => {
        this.setState({mainMenu: !this.state.mainMenu})
    };

    render() {
        return (
            <div className="stickyPanel" style={{height: this.props.stickyHeight}}>
                <div id="stickyMenu" className={ this.props.sticky ? "menu-sticky is-sticky" : "menu-sticky"}>
                    <div className="cont-sticky">
                        <Logo link="#top" colored />
                        <BurgerMenu className="burger-menu-main" onClick={this.toggleMainMenu} />

                        <div className="navigation">
                            <Nav className={ this.state.mainMenu ? "nav-menu open": "nav-menu"}>
                                <NavItem href={this.PROFILE_WEB + '/artists'} >Artistas</NavItem>
                                <NavItem href={this.PRODUCER_WEB } >Produtores</NavItem>
                                <NavItem href={this.PRODUCER_WEB + '/agencies' } >Agentes e Agências</NavItem>
                                {/* 
                                <NavItem href={this.BASE_URL + '/tv'} >OOPPAH PLAY</NavItem>
                                <NavItem href={this.BASE_URL + '/news'} >News</NavItem>
                                */}
                                <NavItem ><Link to="/faq">FAQ</Link></NavItem>
                                <LogoButton className="logoStore" link="https://store.ooppah.com/" title="Ooppah Store" alt="Ooppah Store"
                                    logo={logoStore} width={50}
                                />
                            </Nav>
                            {/* 
                            <div className="dropdown-menu">
                                <div className="burger-menu" onClick={() => this.toggleDropdown()} />
                                <div className={ this.state.dropdownmenu ? "dropdown-nav open" : "dropdown-nav"} >
                                    <Nav>
                                        <NavItem href={this.BASE_URL + '/artistas'} target="_blank">Artistas</NavItem>
                                        <NavItem href={this.BASE_URL + '/site/produtores'} target="_blank">Produtores</NavItem>
                                        <NavItem href={this.BASE_URL + '/site/agencias'} target="_blank">Agências</NavItem>
                                        <NavItem href={this.BASE_URL + '/site/agentes'} target="_blank">Agentes</NavItem>
                                        <NavItem href={this.BASE_URL + '/tv'} target="_blank">TV</NavItem>
                                    </Nav>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </div>    
        )
    }
}
    