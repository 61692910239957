import React, {useRef, useState} from 'react';
import axios from 'axios';

import ActCodeForm from '../../components/ActCodeForm';
import CollectInfoUserFree from '../../components/CollectInfoUserFree';

import {Button, ButtonFreeAccont, Container, ContainerTop, ContentButton, InputRadio, Label, MainContent, RadioButtonArea} from './style';
import Spinner from '../../components/Spinner';
import ActCodeFooter from '../../components/ActCodeFooter';
import FlashMessage from '../../components/FlashMessage';

const ActCodeAndRegistrationFree = () => {
    //Controla o estado atual do formulário
    const [isFree, setIsFree] = useState(true);
    const [currentState, setCurrentState]   = useState(2);
    const [selectedPlan, setSelectedPlan]   = useState({});
    const [identity, setIdentity]           = useState({});
    const [address, setAddress]             = useState({});
    const [isMinor, setIsMinor]             = useState(false);
    const [flashMessages, setFlashMassages] = useState({
        show: false,
        message: {},
        withCustomErrors: false
    });

    const setFlashMessageInfos = (show, withCustomErrors, message) => {
        setFlashMassages({
            show: show,
            withCustomErrors: withCustomErrors,
            message: {
                ...message
            },
            
        });
    }

    const showContent = (state) => {
        if(state === currentState) return true;
        return false;
    }

    const setUserPlan = (plan) => {
        setSelectedPlan(plan);
        setCurrentState(2);
    }

    const setUserGeralInformation = (identity, address) => {
        setIdentity({...identity});
        setAddress({...address});
        setCurrentState(3);
    }

    const sendRegistrationInfo = async (identity, address) =>{
        
        let data = {};

        let formData = {...identity, ...address};

        Object.keys(formData).forEach((item) => {
            data[item] = formData[item].value;
        });

        if(!isFree){
            data['plan_id'] = selectedPlan.id;
            data['payment_method'] = selectedPlan.payment_method;
            data['coupon_code'] = selectedPlan.coupon.code;
        }

        data['type'] = isMinor ? 'minor' : 'major';

        const headers = {'Content-Type': 'application/json'}
        setCurrentState(3);
        const url = isFree ? `${process.env.REACT_APP_API_REGISTER}/freeregister` :
                `${process.env.REACT_APP_API_REGISTER}/register`;
        axios.post(url, data, { headers: headers
            }).then((response) => {
            if(response.status == 201){
                setCurrentState(4);
            }
        }).catch((error) => {
            let data = error.response.data;
            let errors = {};

            Object.keys(data.errors).forEach(item => {
                // console.log(item, json.errors[item][0]);
                errors[item] = data.errors[item][0]
            });

            setFlashMessageInfos(true, true, errors);
            setCurrentState(2);
        });

    }

    const handleOptionChange = (value) => {
        
        const selectedOption = value;
        setCurrentState(1);
        if(selectedOption == 0){

            setIsFree(true);
            setCurrentState(2);
        } else{
            setIsFree(false);
            setCurrentState(1);
        }
        
    };

    const changeStateFree = (changeEvent) =>{

        setCurrentState( parseInt(changeEvent.target.value));
    }

    return (
        <Container id = "activationcode">
            <ContainerTop >
                <h2>Faça seu cadastro aqui</h2>
                {(!isFree) &&
                    <p>Insira o <b>código de ativação</b> que você recebeu por e-mail e faça um upgrade na sua vida profissional!</p>
                }
                {(isFree) &&
                    <p>O perfil gratuito é destinado apenas a <b style={{color: 'red'}}>maiores de 18 anos</b>.</p>
                }
            </ContainerTop>
            
            <MainContent>
                <FlashMessage show = {flashMessages.show} withErrors = {true} customError = {flashMessages.withCustomErrors} content = {flashMessages.message} />
                {showContent(1) && (!isFree) && <ActCodeForm setUserPlan = {setUserPlan} isMinor = {isMinor} setIsMinor = {setIsMinor}/> }
                <CollectInfoUserFree show = {showContent(2)} sendRegistrationInfo = {sendRegistrationInfo} isMinor = {isMinor} setFlashMessageInfos = {setFlashMessageInfos}/> 
                {showContent(3) && 
                    <div align="center" style={{paddingTop: '100px', paddingBottom: '50px'}}>
                        <h1 style={{fontSize: '24px'}}>
                            <Spinner show={true} />
                            {/*MUDANCA-PROFILE*/}
                            &nbsp; &nbsp;Cadastrando, aguarde...
                            {/*MUDANCA-PROFILE*/}
                        </h1>
                    </div>
                }
                {showContent(4) &&
                    <div align="center" style={{paddingTop: '100px', paddingBottom: '50px'}}>
                        <h1 style={{fontSize: '24px'}}>
                            Obrigado, seu cadastro será processado pelo sistema.
                            Verifique a sua caixa de e-mail, SPAMs e aba de promoções para possíveis notificações.
                        </h1>
                    </div>
                }
                {(showContent(2))  && (!isFree) && 
                    <ActCodeFooter
                        currentState = {currentState} 
                        planName = {selectedPlan.name}
                        coupon = {selectedPlan.coupon}
                        setCurrentState = {setCurrentState}
                    />
                }
            </MainContent>
            
        </Container>
    );
}

export default ActCodeAndRegistrationFree;