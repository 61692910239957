import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextField from "../TextField/index";
import * as moment from 'moment';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    feedbackText: PropTypes.string,
    mask: PropTypes.string,
    dateFormat: PropTypes.string,
    required: PropTypes.bool
};

const defaultProps = {
    mask: '11/11/1111',
    dateFormat: 'DD/MM/YYYY',
    feedbackText: 'Data inválida',
    required: false,
    onChange: () => {}
};

class DateField extends Component {

    state = {
        isValid: false
    };

    /**
     * Handle onChange.
     *
     * @param e
     * @param isValid
     */
    handleOnChange = (e, isValid) => {
        e.persist();
        const inputValueLength = e.target.value.replace(/[\/_]/g,'').length; // accepted chars length.
        const dateIsValid = moment(e.target.value, this.props.dateFormat).isValid();

        this.setState({
            isValid: (inputValueLength === 8) && dateIsValid
        }, () => {
            this.props.onChange(e, this.state.isValid);
        });
    };

    render() {
        return (
            <TextField
                type="tel" // hacking to show a numeric keyboard to the user.
                label={this.props.label}
                name={this.props.name}
                mask={this.props.mask}
                feedbackText={this.props.feedbackText}
                isValid={this.state.isValid}
                onChange={this.handleOnChange}
                required={this.props.required}
            />
        );
    }
}

DateField.propTypes = propTypes;
DateField.defaultProps = defaultProps;

export default DateField;