import React, { Component } from 'react';
import './style.css'

const propTypes = {};

const defaultProps = {};

class MinorAlert extends Component {
    state = {};

    render() {
        return (
            <div className="minor-alert">
                <p><strong>Atenção</strong></p>

                <p className="text-justify">
                    Por exigência do órgão regulador, solicitamos o envio da <a
                    href="https://docs.ooppah.com/authorization-of-image"
                    className="authorization-link"
                    target="_blank"
                    >Autorização de Menores de Idade</a>, 
                    assinada pelo responsável legal do menor via e-mail. Não é necessária a autenticação do 
                    documento em cartório nem envio por correio.
                </p>
            </div>
        );
    }
}

MinorAlert.propTypes = propTypes;
MinorAlert.defaultProps = defaultProps;

export default MinorAlert;
