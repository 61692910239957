import React from 'react';
import {ArtistaSection} from './style';

import successImage from '../../assets/SuccessCases.jpeg';



const SuccessCases = () => {

    return (
        <ArtistaSection id="artista">
            <div className="artista-topo">
                <h2>Aqui você brilha!</h2>
                <p>Conheça alguns artistas Ooppah que saíram na frente rumo ao sucesso.</p>
            </div>
            <div className = "centralized margin-bottom">
                <img src={successImage} alt="Casos de sucesso" />
            </div>
        </ArtistaSection>
    );
}

export default SuccessCases;