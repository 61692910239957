import styled from 'styled-components';


export const Container = styled.div`
    display:flex;
    height: 50px;
    margin-bottom: 10px;
    width: 95%;
`;

export const BtnSearch = styled.button`
    border: none;
    cursor:pointer;
    background-color: #f5f3f0;
    &:hover {
        background-color: #c7c9c8;
    }
    border-radius 5px;
    margin-left: 5px;
`;

export const IconSearch = styled.img`
    height: 25px;
    width: 30px;
`;