import React, { useRef, useState } from "react";
import { Container, Content, LinkButton, LoginButton } from "./style";
import { useClickedOutside } from "../../hooks/useClickedOutside";
import HamburguerMenu from "../../assets/threeBar.svg";
import Icon from "../Icon";
const LoginDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const openDrop = () => setIsOpen(true);
  const closeDrop = () => setIsOpen(false);
  useClickedOutside(dropdownRef, closeDrop, isOpen);

  return (
    <Container ref={dropdownRef}>
      <LoginButton onClick={openDrop}>
        Login
        <Icon src={HamburguerMenu} />
      </LoginButton>
      {isOpen && (
        <Content>
          <LinkButton href={process.env.REACT_APP_PROFILE_WEB}>
            <span>Artista</span>
          </LinkButton>
          <LinkButton href={process.env.REACT_APP_PRODUCER_WEB + '/login'}>
            <span>Produtor</span>
          </LinkButton>
          <LinkButton  href={process.env.REACT_APP_PRODUCER_WEB + '/login'} >
            <span>Agência</span>
          </LinkButton>
        </Content>
      )}
    </Container>
  );
};
export default LoginDropdown;