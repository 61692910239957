import styled from 'styled-components';



export const ArtistaSection = styled.div`
    background-color: #FFFFFF;
    display: block;
    padding: 50px 0 0 0;
    width: 100%;
    @media (min-width: 1080px) {
        padding: 50px 0 0 0;
    }

    .centralized {
        display:flex;
        justify-content:center;
        align-items:center;
    }

    .margin-bottom {
        margin-bottom: 50px;
    }

    .artista-topo {
        display: block;
        width: 100%;
        text-align: center;
        padding: 0 30px;

        h2 {
            color: #616061;
            font-size: 42px;
            display: block;
            width: 100%;
            margin-bottom:10px;
            font-weight: 300;
            line-height: 1.2;
            @media (min-width: 1080px) {
                font-size: 62px;
            }
        }

        p {
            color: #616061;
            font-size: 14px;
        }
    }
`;

