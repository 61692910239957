import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

import './style.css'

const propTypes = {
    withSuccess: PropTypes.bool,
    withErrors: PropTypes.bool,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    show: PropTypes.bool.isRequired,
    placeholderMessage: PropTypes.string,
    customError: PropTypes.bool
};

const defaultProps = {
    success: false,
    errors: false,
    show: false,
    placeholderMessage: 'O campo :attribute deve ser preenchido corretamente.',
    customError: false
};

class FlashMessage extends Component {
    state = {};

    errorsToHtml = errors => {
        const title = <p>
            <strong style={{fontWeight: 600}}>
                Ops... Há algo errado com o preenchimento do formulário:
            </strong><br/><br/>
        </p>;

        let html = ReactDOMServer.renderToStaticMarkup(title) + '<ul>';

        Object.values(errors).forEach(item => {
            html += '<li>';
            html += this.props.customError ? item : this.props.placeholderMessage.replace(':attribute', item);
            html += '</li>';
        });

        html += '</ul>';

        return html;
    };

    content = () => {
        if (this.props.withSuccess) {
            return this.props.content;
        }

        return this.errorsToHtml(this.props.content);
    };

    // componentDidUpdate = () => {
    //     const element = document.getElementById('flash-message').getBoundingClientRect();
    //     const body = document.body.getBoundingClientRect();
    //
    //     // scroll window to show flash messages to the user...
    //     // if (this.props.show) {
    //         // window.scrollTo(0, (body.top - element.top)*-1)
    //     // }
    // };

    render() {
        const className = ['flash-message'];
            className.push(this.props.withSuccess ? 'with-success' : 'with-errors');

        const style = {
            display: this.props.show ? 'block' : 'none',
            border: '1px solid ' + (this.props.withSuccess ? 'green' : 'red'),
            color: this.props.withSuccess ? 'green' : 'red',
        };

        return (
            <div
                id="flash-message"
                className={ className.join(' ') }
                style={style}
                dangerouslySetInnerHTML={{__html: this.content() }}
            />
        );
    }
}

FlashMessage.propTypes = propTypes;
FlashMessage.defaultProps = defaultProps;

export default FlashMessage;
