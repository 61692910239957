import React, { Component } from 'react'
import { useLocation } from 'react-router-dom'

// Style
import {
    Container,
    Nav,
    NavItem,
    Navigation,
    ActionButtons,
    LoginButton,
    RegisterButton
} from './styles';

// Images e Assets
import BurgerMenu from "../BurgerMenu";
import Logo from "../Logo";
import OoppahStore from '../OoppahStoreMenu';
import logoPlay from './../../assets/ooppah_play.png'
import LogoButton from '../LogoButton';
import RegisterDropdown from '../RegisterDrop';
import LoginDropdown from '../LoginDrop';

export default class Menu extends Component {

    BASE_URL = process.env.REACT_APP_MONOLITICO_BASE;
    PROFILE_WEB = process.env.REACT_APP_PROFILE_WEB;
    PRODUCER_WEB = process.env.REACT_APP_PRODUCER_WEB;

    constructor(props){
        super(props);
        this.state = {
            dropDownMenu: false
        };
    }

    decideUrlLink = () => {
        let location = window.location.pathname;
        return location === '/' ? "#top": process.env.REACT_APP_HOME + "#top";
    }

    
    toggleDropdown = () => {
        this.setState({dropDownMenu: !this.state.dropDownMenu})
    };

    render() {
        return (
            <Container style = {this.props.isStatic? {position: 'static'}:{}}>
                <Logo link={this.decideUrlLink('top')} className="logo" />
                <BurgerMenu onClick={this.toggleDropdown} className="burger-menu" />

                <Navigation>
                    <Nav className={ this.state.dropDownMenu ? "nav-menu open": "nav-menu"}>
                        <NavItem href={this.PROFILE_WEB + '/artists'} >Artistas</NavItem>
                        <NavItem href={this.PRODUCER_WEB } >Produtores</NavItem>
                        <NavItem href={this.PRODUCER_WEB + '/agencies' } >Agentes e Agências</NavItem>

                        <OoppahStore isFaq={this.props.isFaq} link="https://store.ooppah.com/" title="Ooppah Store" target={"_blank"} />
                        {/* 
                        <LogoButton link={this.BASE_URL + '/tv'} title="Ooppah Play" alt="Ooppah Play"
                            logo={logoPlay}>
                             PLAY
                        </LogoButton>
                       */}
                    </Nav>
                </Navigation>

                <ActionButtons>
                    <RegisterButton href={this.PRODUCER_WEB}>Pesquisar</RegisterButton>
                    <RegisterDropdown />
                    <LoginDropdown />
                    {/* <LoginButton href={this.BASE_URL + '/entrar'}>login</LoginButton> */}
                </ActionButtons>
                
            </Container>
        )
    }
}
