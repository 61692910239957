import React, {useRef, useState} from 'react';
import TextField from '../formComponents/TextField';
import { Button, Container, ContainerForm } from './style';
import axios from 'axios';

const ActCodeForm = ({isMinor, setIsMinor, setUserPlan}) => {

    const [isValid, setIsvalid] = useState(true);
    const [waiting, setWaiting] = useState(false);

    //Pegar conteúdo escrito do formulário
    const couponField = useRef(null);

    const btnValidateHandler = async (e) => {
        e.preventDefault();
        //Ao inserir o cupom, alterar para true e tirar o erro
        setIsvalid(true);
        setWaiting(true);
        //Pega o conteúdo do input
        let actCode = couponField.current.state.value;
        if (actCode.trim() === '') return setIsvalid(false);
        //prepara a URL de requisição para o BILL
        let apiUrl = `${process.env.REACT_APP_API_REGISTER}/plans?subscriber_type=${isMinor ? 'minor' : 'adult'}&coupon=${actCode}`;
        //Faz a requisição
        let response = await axios.get(apiUrl);
        setWaiting(false);
        //Problema interno do servidor
        if(response.status !== 200) return false;
        let plans = response.data.data;
        //Se vier algo diferente de um plano, o cupom é invalido.
        if(plans.length !== 1) return setIsvalid(false);
        //Se chegar até aqui, foi bem sucedido
        //Atualizar no controlador do form o plano com o cupom.
        setUserPlan(plans[0]);
        
    }

    return (
        <ContainerForm>
            <Container>
                <div className="planos-tipo">
                    <div className={!isMinor ? "btn btn-adulto" : "btn"} onClick={() => setIsMinor(false)}>
                        Artista adulto
                    </div>
                    <div className={isMinor ?  "btn btn-mirim" : "btn"} onClick={() => setIsMinor(true)}>
                        Artista mirim
                    </div>
                </div>
                <TextField
                    ref={couponField}
                    type="text"
                    label="Cole aqui seu código de ativação"
                    name="coupon"
                    value={''}
                    pipe={ v => v.toUpperCase() }
                    waiting={waiting}
                    isValid={isValid}
                    feedbackText="Código de ativação inválido"
                />
                <Button className="btn-validate" onClick={btnValidateHandler}>Validar</Button>
            </Container>
        </ContainerForm>
    );
}

export default ActCodeForm;