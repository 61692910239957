import styled from "styled-components";

export const Container = styled.div`
    border-width: 1px;
    border-style: solid;
    border-color: black;

    padding: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 120px;
    margin: 5px;
`;

export const Icon = styled.img`
    width: 30px;
    height: 30px;
    padding: 10px;
    box-sizing: content-box;
`;

export const Text = styled.span`
    display: inline-block;
    text-align: center;
    font-size: 12px;
`;