import MagnifyingGlass from '../assets/lupa.svg';
import MagnifyingGlass2 from '../assets/busca_especial.svg';
import Share from '../assets/share.svg';
import Folder from '../assets/pasta.svg';
import Statistics from '../assets/estatistica.svg';
import Tag from '../assets/clube.svg';
import ArtistClaudio from '../assets/artist_04.png';
import ArtistNina from '../assets/artist_02.png';
import ArtistAnderson from '../assets/artist_01.png';
import ArtistGigi from '../assets/artist_10.png';
import ArtistMilton from '../assets/artist_07.png';
import ArtistPaulo from '../assets/artist_03.png';
import ArtistAndressa from '../assets/artist_09.png';
import ArtistVinicius from '../assets/artist_06.png';
import ArtistFernando from '../assets/artist_11.png';
import ArtistThiago from '../assets/artist_08.png';
import ArtistFabiano from '../assets/artist_05.png';

import StarHand from '../assets/pontos.svg';
import VideoIcon from '../assets/video.svg';
import CamIcon from '../assets/camera.svg';
import Ads from '../assets/ads.svg';
import SealIcon from '../assets/selo_ooppah.svg';
import Ticket from '../assets/clube.svg';
import Claquete from '../assets/claquete.svg';
import Target from '../assets/foco.svg';
import Checked from '../assets/visto.svg';
import ProfileIcon from '../assets/perfil.svg';
import Idea from '../assets/ideia.svg'

export const freePlan = [
    {
        name: 'Perﬁl disponível para <b>pesquisa pública</b>',
        icon: MagnifyingGlass,
        isActivated: true
    },
    {
        name: 'Link com perﬁl pessoal <b>personalizado</b> e <b>compartilhável</b>',
        icon: Share,
        isActivated: true
    },
    {
        name: 'Acesso a Dicas, Notícias e <b>testes de elenco</b> do mercado',
        icon: Folder,
        isActivated: true
    },
    {
        name: 'Até <span class="color">3</span> fotos em sua página de perfil',
        icon: CamIcon,
        isActivated: true
    },
    {
        name: 'Até <span class="color">1</span> vídeo em sua página de perfil',
        icon: VideoIcon,
        isActivated: true
    },
    {
        name: '<b>Livre</b> de anúncios',
        icon: Ads,
        isActivated: false
    },
    {
        name: 'Ganhe um destaque com até +1000 <b>Pontos extras</b> no algoritmo de busca',
        icon: StarHand,
        isActivated: false
    },
    {
        name: 'Selo Ooppah para <b>impulsionar</b> seu perfil nas pesquisas',
        icon: SealIcon,
        isActivated: false
    },
    {
        name: 'Contador de pesquisa e visitas ao seu perfil',
        icon: Statistics,
        isActivated: false
    },
    {
        name: 'Acesso ao clube de vantagens Ooppah',
        icon: Ticket,
        isActivated: false
    },
    {
        name: 'Desconto em videobooks, sessão de fotos e workshops',
        icon: Claquete,
        isActivated: false
    },
    {
        name: 'Desconto em Mentoria personalizada de carreira',
        icon: ProfileIcon,
        isActivated: false
    },
    {
        name: 'Orientações personalizadas para realização de self-tape requisitada pelo mercado',
        icon: Target,
        isActivated: false
    },
    {
        name: 'Avaliação de material',
        icon: Checked,
        isActivated: false
    },
];

export const lightPlan = [
    {
        name: 'Perﬁl disponível para pesquisa <b> pública e privada*</b>',
        icon: MagnifyingGlass,
        isActivated: true
    },
    {
        name: 'Link com perﬁl pessoal <b>personalizado</b> e <b>compartilhável</b>',
        icon: Share,
        isActivated: true
    },
    {
        name: 'Acesso a Dicas, Notícias e <b>testes de elenco</b> do mercado',
        icon: Folder,
        isActivated: true
    },
    {
        name: 'Até <span class="color">8</span> fotos em sua página de perfil',
        icon: CamIcon,
        isActivated: true
    },
    {
        name: 'Até <span class="color">3</span> vídeos em sua página de perfil',
        icon: VideoIcon,
        isActivated: true
    },
    {
        name: '<b>Livre</b> de anúncios',
        icon: Ads,
        isActivated: true
    },
    {
        name: 'Ganhe um destaque com até <span class="color">+100</span> <b>Pontos extras</b> no algoritmo de busca',
        icon: StarHand,
        isActivated: true
    },
    {
        name: 'Selo Ooppah para <b>impulsionar</b> seu perfil nas pesquisas',
        icon: SealIcon,
        isActivated: false
    },
    {
        name: 'Contador de pesquisa e visitas ao seu perfil',
        icon: Statistics,
        isActivated: false
    },
    {
        name: 'Acesso ao clube de vantagens Ooppah',
        icon: Ticket,
        isActivated: false
    },
    {
        name: 'Desconto em videobooks, sessão de fotos e workshops',
        icon: Claquete,
        isActivated: false
    },
    {
        name: 'Desconto em Mentoria personalizada de carreira',
        icon: ProfileIcon,
        isActivated: false
    },
    {
        name: 'Orientações personalizadas para realização de self-tape requisitada pelo mercado',
        icon: Target,
        isActivated: false
    },
    {
        name: 'Avaliação de material',
        icon: Checked,
        isActivated: false
    },
];

export const premiumPlan = [
    {
        name: 'Perﬁl disponível para pesquisa <b> pública e privada*</b>',
        icon: MagnifyingGlass,
        isActivated: true
    },
    {
        name: 'Link com perﬁl pessoal <b>personalizado</b> e <b>compartilhável</b>',
        icon: Share,
        isActivated: true
    },
    {
        name: 'Acesso a Dicas, Notícias e <b>testes de elenco</b> do mercado',
        icon: Folder,
        isActivated: true
    },
    {
        name: 'Até <span class="color">20</span> fotos em sua página de perfil',
        icon: CamIcon,
        isActivated: true
    },
    {
        name: 'Até <span class="color">20</span> vídeos em sua página de perfil',
        icon: VideoIcon,
        isActivated: true
    },
    {
        name: '<b>Livre</b> de anúncios',
        icon: Ads,
        isActivated: true
    },
    {
        name: 'Ganhe um destaque com até <span class="color">+500</span> <b>Pontos extras</b> no algoritmo de busca',
        icon: StarHand,
        isActivated: true
    },
    {
        name: 'Selo Ooppah <b>Prata</b> para <b>impulsionar</b> seu perfil nas pesquisas',
        icon: SealIcon,
        isActivated: true
    },
    {
        name: 'Contador de <b>pesquisa</b> e <b>visitas</b> ao seu perfil',
        icon: Statistics,
        isActivated: true
    },
    {
        name: 'Acesso ao clube de vantagens Ooppah',
        icon: Ticket,
        isActivated: true
    },
    {
        name: 'Desconto <span class="color">(25%)</span>** em <b>videobooks</b>, sessão de fotos e workshops',
        icon: Claquete,
        isActivated: true
    },
    {
        name: 'Desconto <span class="color">(25%)</span>** em <b>Mentoria</b> personalizada de carreira',
        icon: ProfileIcon,
        isActivated: true
    },
    {
        name: 'Orientações personalizadas para realização de self-tape requisitada pelo mercado',
        icon: Target,
        isActivated: false
    },
    {
        name: 'Avaliação de material',
        icon: Checked,
        isActivated: false
    },
];

export const vipPlan = [
    {
        name: 'Perﬁl disponível para pesquisa <b> pública e privada*</b>',
        icon: MagnifyingGlass,
        isActivated: true
    },
    {
        name: 'Link com perﬁl pessoal <b>personalizado</b> e <b>compartilhável</b>',
        icon: Share,
        isActivated: true
    },
    {
        name: 'Acesso a Dicas, Notícias e <b>testes de elenco</b> do mercado',
        icon: Folder,
        isActivated: true
    },
    {
        name: 'Até <span class="color">40</span> fotos em sua página de perfil',
        icon: CamIcon,
        isActivated: true
    },
    {
        name: 'Até <span class="color">40</span> vídeos em sua página de perfil',
        icon: VideoIcon,
        isActivated: true
    },
    {
        name: '<b>Livre</b> de anúncios',
        icon: Ads,
        isActivated: true
    },
    {
        name: '<span class="color">+1.000</span> <b>Pontos</b> extras no algoritmo de busca',
        icon: StarHand,
        isActivated: true
    },
    {
        name: 'Selo Ooppah <b>Ouro</b> para <b>impulsionar</b> seu perfil nas pesquisas',
        icon: SealIcon,
        isActivated: true
    },
    {
        name: 'Contador de <b>pesquisa</b> e <b>visitas</b> ao seu perfil',
        icon: Statistics,
        isActivated: true
    },
    {
        name: 'Acesso ao clube de vantagens Ooppah',
        icon: Ticket,
        isActivated: true
    },
    {
        name: 'Desconto <span class="color">(50%)</span>** em <b>videobooks</b>, sessão de fotos e workshops',
        icon: Claquete,
        isActivated: true
    },
    {
        name: 'Desconto <span class="color">(50%)</span>** em <b>Mentoria</b> personalizada de carreira',
        icon: ProfileIcon,
        isActivated: true
    },
    {
        name: 'Orientações personalizadas para realização de self-tape requisitada pelo mercado',
        icon: Target,
        isActivated: true
    },
    {
        name: 'Avaliação de material',
        icon: Checked,
        isActivated: true
    },
];

export const competitorPlan = [
    {
        name: 'Perﬁl disponível para <b>pesquisa pública</b>',
        icon: MagnifyingGlass,
        isActivated: false
    },
    {
        name: 'Link com perﬁl pessoal <b>personalizado</b> e <b>compartilhável</b>',
        icon: Share,
        isActivated: true
    },
    {
        name: 'Acesso a Dicas, Notícias e <b>testes de elenco</b> do mercado',
        icon: Folder,
        isActivated: false
    },
    {
        name: '<b>Destaque</b> nas pesquisas de elenco',
        icon: MagnifyingGlass2,
        isActivated: true
    },
    {
        name: 'Contador de <b>pesquisa</b> e <b>visitas</b> ao seu perﬁl',
        icon: Statistics,
        isActivated: true
    },
    {
        name: 'Desconto em clube de benefícios',
        icon: Tag,
        isActivated: false
    }
];

export const featuredPlan = [
    {
        name: 'Perﬁl disponível para <b>pesquisa pública</b>',
        icon: MagnifyingGlass,
        isActivated: true
    },
    {
        name: 'Link com perﬁl pessoal <b>personalizado</b> e <b>compartilhável</b>',
        icon: Share,
        isActivated: true
    },
    {
        name: 'Acesso a Dicas, Notícias e <b>testes de elenco</b> do mercado',
        icon: Folder,
        isActivated: true
    },
    {
        name: 'Até <span class="color">40</span> fotos em sua página de perfil',
        icon: CamIcon,
        isActivated: true
    },
    {
        name: 'Até <span class="color">40</span> vídeos em sua página de perfil',
        icon: VideoIcon,
        isActivated: true
    },
    {
        name: '<b>Livre</b> de anúncios',
        icon: Ads,
        isActivated: true
    },
    {
        name: 'Ganhe um destaque com até <span class="color">+1.000</span> <b>Pontos</b> extras no algoritmo de busca',
        icon: StarHand,
        isActivated: true
    },
    {
        name: 'Selo Ooppah para <b>impulsionar</b> seu perfil nas pesquisas',
        icon: SealIcon,
        isActivated: true
    },
    {
        name: 'Contador de <b>pesquisa</b> e <b>visitas</b> ao seu perfil',
        icon: Statistics,
        isActivated: true
    },
    {
        name: 'Acesso ao clube de vantagens Ooppah',
        icon: Ticket,
        isActivated: true
    },
    {
        name: 'Descontos em <b>videobooks</b>, sessão de fotos e workshops',
        icon: Claquete,
        isActivated: true
    },
    {
        name: 'Descontos em <b>Mentoria</b> personalizada de carreira',
        icon: ProfileIcon,
        isActivated: true
    },
    {
        name: 'Orientações personalizadas para realização de self-tape requisitada pelo mercado',
        icon: Target,
        isActivated: true
    },
    {
        name: 'Avaliação de material',
        icon: Checked,
        isActivated: true
    },
];

export const SuccessCases = [
    {
        name: "Cláudio Márcio",
        avatar: ArtistClaudio,
        rating: 5,
        declaration: 'Fui achado no Ooppah para fazer a série O Rei da TV. Funciona... Eu sou prova!'
    },
    {
        name: "Nina Baiocchi",
        avatar: ArtistNina,
        rating: 4.5,
        declaration: 'Eu fui chamada logo pra fazer uma personagem!'
    },
    {
        name: "Anderson Luri",
        avatar: ArtistAnderson,
        rating: 5,
        declaration: 'Fiz Sob Pressão, Impuros da Fox e já estou no meu terceiro trabalho achado pelo Ooppah'
    },
    {
        name: "Gigi Patta",
        avatar: ArtistGigi,
        rating: 5,
        declaration: 'Fui chamada pro musical A Noviça Rebelde de Möeller e Botelho. Realizei um sonho!'
    },
    {
        name: "Milton Lacerda",
        avatar: ArtistMilton,
        rating: 5,
        declaration: 'Fiz uma longa na Turquia através do Ooppah!'
    },
    {
        name: "Paulo de Melo",
        avatar: ArtistPaulo,
        rating: 4.5,
        declaration: 'Ela abriu possibilidades reais de trabalho! Gravei longa, gravei série...'
    },
    {
        name: "Andressa Robles",
        avatar: ArtistAndressa,
        rating: 4.5,
        declaration: 'Peguei uma novela pelo Ooppah'
    },
    {
        name: "Vinícius Patrício",
        avatar: ArtistVinicius,
        rating: 5,
        declaration: 'Em uma semana de plataforma eu recebi dois convites'
    },
    {
        name: "Fernando Daghlian",
        avatar: ArtistFernando,
        rating: 5,
        declaration: 'Eu já tive algumas oportunidades... Um Contra Todos da FOX, DOM da Amazon Prime'
    },
    {
        name: "Thiago Fonseca",
        avatar: ArtistThiago,
        rating: 5,
        declaration: 'A produtora do filme que fiz me achou no Ooppah e me convidou, sem fazer teste!'
    },
    {
        name: "Fabiano Persi",
        avatar: ArtistFabiano,
        rating: 4.5,
        declaration: 'A partir do momento que eu conheci o Ooppah e coloquei o meu material ali, as coisas aconteceram'
    },

];

