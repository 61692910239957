import React, {useRef, useState} from "react";
import MinorAlert from "../MinorAlert";
import Address from "../RegisterForm/Address";
import IdentityFree from "../RegisterForm/IdentityFree";

import {Button, Container, ContentButton, PlanTerms} from './style'; 

const CollectInfoUserFree = ({show = true, isMinor, sendRegistrationInfo, setFlashMessageInfos}) => {
    const identityComponent = useRef(null);
    const addressComponent = useRef(null);
    const [checkBox, setCheckBox] = useState(false);


    const validate = (e) => {
        e.preventDefault();
        //Chama os métodos de validação dos campos do usuário
        const identityComponentValidation = identityComponent.current.validate();
        const addressComponentValidation = addressComponent.current.validate();
        //Campo de validação adiciona para idade
        //Checa se ambos os campos de address e identity estao validos
        let isValid = identityComponentValidation.isValid && addressComponentValidation.isValid;
        //Se não estiver valido, é necessario avisar ao usuario dos campos invalidos
        
        //
        setFlashMessageInfos(
            !isValid, 
            false, 
            {...identityComponentValidation.fields.invalid, ...addressComponentValidation.fields.invalid}
        );
        
        if(!isValid) return false;
        //se passar, significa que o usuário esta pronto para o proximo passo
        sendRegistrationInfo(identityComponentValidation.values, addressComponentValidation.values);
        //setUserGeralInformation(identityComponentValidation.values, addressComponentValidation.values);
    }
    
    return (
        <Container show = {show} >
            {isMinor && <MinorAlert />}
            <div className = {isMinor ? 'minor':'adult'}>
                <IdentityFree ref = {identityComponent} minor = {isMinor} show = {true} />
                <Address ref = {addressComponent} show = {true} />
            </div>
            <ContentButton>
                <PlanTerms> 
                    <label>
                        <input type="checkbox"
                            defaultChecked={checkBox}
                            onChange={() => setCheckBox(!checkBox)}
                        />
                    </label>   
                    <label>
                        Li e aceito os &nbsp;
                        <a href="https://docs.ooppah.com/terms-of-use" target="_blank">
                            termos de uso 
                        </a>
                        &nbsp;do OOPPAH.
                    </label>
                </PlanTerms>
                <div className = "central-button">
                    <Button disabled={!checkBox} onClick = {validate}>Finalizar</Button>
                </div>
            </ContentButton>
           
            
        </Container>
    );
}

export default CollectInfoUserFree;
