import React from 'react'

import Rodape from '../../containers/Rodape';
import Menu from '../../components/menu';
import MenuBackground from '../../components/MenuBackground';
import FAQBody from '../../containers/FAQBody';
import FloatActionButton from '../../components/FloatActionButton';
import LogoWp from '../../assets/LogoWP.png';

import {Title} from './style';
import { Helmet } from 'react-helmet';

const FAQ = () => {

    return (
        <div style = {{backgroundColor: '#f7f7f7'}}>
            <Helmet>
                <title>Ooppah! FAQ - Dúvidas Frequentes</title>
                <meta name='description' content="Tire aqui as suas dúvidas através do FAQ sobre a ferramenta Ooppah e suas funcionalidades!"/>
                <meta property="og:title" content="Ooppah! FAQ - Dúvidas Frequentes"/>
                <meta property="og:description" content="Tire aqui as suas dúvidas através do FAQ sobre a ferramenta Ooppah e suas funcionalidades!" />
            </Helmet>

            <MenuBackground>
                <Menu isFaq={true} isStatic= {true}/>
            </MenuBackground>
            <Title>FAQ - Perguntas Frequentes</Title>
            <FAQBody />
            <Rodape />
            <FloatActionButton linkTo = "https://api.whatsapp.com/send?1=pt_BR&phone=+5521993330503">
				<img src = {LogoWp} />
			</FloatActionButton>
        </div>
    );
}

export default FAQ;