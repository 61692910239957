import React from 'react';
import { withBaseIcon } from 'react-icons-kit';
import {ic_volume_off  as volumeMute } from 'react-icons-kit/md/ic_volume_off'
import {ic_volume_up  as volumeUp } from 'react-icons-kit/md/ic_volume_up'

const IconContainer = withBaseIcon({
    size: 32,
    style: {
        color: '#FFF'
    }
});

export const SoundOn = props => <IconContainer icon={volumeUp} {...props} />;
export const SoundOff = props => <IconContainer icon={volumeMute} {...props} />;