import styled from "styled-components";

export const Container = styled.div`

`;

export const Main = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3px 7px;
    
`;

export const Title = styled.h1`
    font-size: 25px;
    font-weight: bold;
`;

export const Description = styled.p`

`;


export const Questions = styled.section`
    max-width: 800px;
    width: 100%
`;

export const FinishButton = styled.button`
    font-weight: bold;
    font-size: 25px;
    background-color: #19a83f
    box-shadow: 3px 2px 2px black;
    padding: 3px 12px;
    color: white;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background-color: #11bf3f;
    }

    &:disabled {
        background-color: #064516;
        color: #636363
    }
`;