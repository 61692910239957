import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    data: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    minor: PropTypes.bool.isRequired,
    coupon: PropTypes.object
};

const defaultProps = {
    plan: {},
    coupon: {}
};

class Review extends Component {
    state = {};

    address = () => {
        const data = this.props.data;

        let address = data.address.value + ', ' + data.address_number.value;

            address += data.hasOwnProperty('address_complement') && data.address_complement.value !== ''
                       ? ', ' + data.address_complement.value : '';

            address += ', ' + data.address_district.value;
            address += ', ' + data.address_city.value;
            address += ', ' + data.address_state.value;
            address += ', ' + data.zipcode.value + '.';

        return address;
    };

    render() {

        const ccInfo = () => <div className="bloco bloco-3">
            <div className="bloco-topo">DADOS DE COBRANÇA</div>
            <div className="bloco-cont">
                <div className="bloco-cont-linha">
                    <div className="texto">Cartão</div>
                    <div className="texto">
                        {this.props.data.cc_number.value}
                    </div>
                </div>
                <div className="bloco-cont-linha">
                    <div className="texto">Validade</div>
                    <div className="texto">
                        {this.props.data.cc_expiration_month.value}/{this.props.data.cc_expiration_year.value}
                    </div>
                </div>
                <div className="bloco-cont-linha">
                    <div className="texto">Nome</div>
                    <div className="texto">{this.props.data.cc_holder_name.value}</div>
                </div>
            </div>
        </div>;

        const boletoInfo = () => <div className="bloco bloco-3">
            <div className="bloco-topo">DADOS DE COBRANÇA</div>
            <div className="bloco-cont">
                <p>
                    Sua assinatura será paga através de boleto bancário. Ao final deste processo
                    você reberá o boleto no seu e-mail.
                </p>
            </div>
        </div>;

        return (
            <div className="formulario-artista">
                <div className="registro-assinatura">
                    <div className="registro-assinatura-topo">
                        <div className="assinatura-topo-titulo">REGISTRO DE NOVA ASSINATURA</div>
                        <div className="assinatura-topo-texto">
                            Agora você só precisa revisar as informações e se tudo estiver correto já pode finalizar!
                        </div>
                    </div>
                    <div className="registro-assinatura-cont">
                        <div className="linha">
                            <div className="bloco bloco-3">
                                <div className="bloco-topo">PLANO SELECIONADO</div>
                                <div className="bloco-cont laranja">
                                    <div className="selecionado">
                                        <h2>
                                            {this.props.plan.name.toUpperCase()} &nbsp;
                                            R$ { this.props.plan.amount }
                                            /{ this.props.plan.unit === 'M' ? 'Mês' : 'Ano' }
                                        </h2>
                                        <p>{this.props.plan.description}</p>
                                        <p>
                                            De  R$&nbsp; {this.props.plan.amount}/{this.props.plan.unit === 'M' ? 'Mês' : 'Ano'}&nbsp;
                                            Por  R$&nbsp; { this.props.plan.amount_from }/{ this.props.plan.unit === 'M' ? 'Mês' : 'Ano' }
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="bloco bloco-3">
                                <div className="bloco-topo">ENTENDA OS VALORES</div>
                                <div className="bloco-cont">

                                    <div className="bloco-cont-linha">
                                        <div className="texto">Plano</div>
                                        <div className="texto">
                                            R$&nbsp;
                                            { this.props.plan.amount } <span className="sinal">(+)</span>
                                        </div>
                                    </div>

                                    {
                                        this.props.coupon !== null && this.props.coupon.hasOwnProperty('id') &&
                                        <div className="bloco-cont-linha">
                                            <div className="texto">Cupom</div>
                                            <div className="texto">
                                                { this.props.coupon.discount.presentable } <span className="sinal">(-)</span>
                                            </div>
                                        </div>
                                    }

                                    <div className="bloco-cont-linha">
                                        <div className="texto">Total a pagar</div>
                                        <div className="texto">
                                            R$&nbsp;

                                            {
                                                // Subtrai disconto, caso o valor do desconto não seja um percentual...
                                                this.props.coupon !== null && this.props.coupon.hasOwnProperty('id') &&
                                                // this.props.coupon.discount.type === '2' &&
                                                (
                                                    parseFloat(this.props.plan.amount) -
                                                    parseFloat(this.props.coupon.discount.value)
                                                ).toFixed(2)
                                            }

                                            {
                                                // Se não houver disconto, simplesmente apresenta o valor do plano...
                                                this.props.coupon === null && this.props.plan.amount
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            { this.props.plan.payment_method === 'C' ? ccInfo() : boletoInfo() }

                        </div>

                        <div className="linha">
                            <div className="bloco">
                                <div className="bloco-topo">DADOS DO CADASTRO</div>
                                <div className="bloco-cont">
                                    <div className="bloco-cont-linha">
                                        <div className="bloco-column">
                                            <div className="bloco-titulo">Artista</div>
                                            <div className="texto-label">Nome Completo</div>
                                            <div className="texto-resposta">{this.props.data.name.value}</div>
                                        </div>
                                        <div className="bloco-column">
                                            <div className="bloco-titulo">&nbsp;</div>
                                            <div className="texto-label">Data de Nascimento</div>
                                            <div className="texto-resposta">{this.props.data.birthday.value}</div>
                                        </div>
                                    </div>

                                    {
                                        this.props.minor &&
                                        <div className="bloco-cont-linha">
                                            <div className="bloco-column">
                                                <div className="bloco-titulo">Responsável Legal</div>
                                                <div className="texto-label">Nome Completo</div>
                                                <div className="texto-resposta">{this.props.data.guardian_name.value}</div>
                                            </div>
                                            <div className="bloco-column">
                                                <div className="texto-label">E-mail</div>
                                                <div className="texto-resposta">{this.props.data.email.value}</div>
                                            </div>
                                            <div className="bloco-column">
                                                <div className="texto-label">CPF</div>
                                                <div className="texto-resposta">{this.props.data.document.value}</div>
                                            </div>
                                            <div className="bloco-column">
                                                <div className="texto-label">Documento de identificação</div>
                                                <div className="texto-resposta">
                                                    {this.props.data.guardian_document2.value}
                                                    - {this.props.data.guardian_document2_issuer.value}
                                                    - Expedição: {this.props.data.guardian_document2_shipping_date.value}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="bloco-cont-linha">
                                        <div className="bloco-column bloco-column-last" >
                                            <div className="texto-label">Endereço</div>
                                            <div className="texto-resposta">
                                                {this.address()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Review.propTypes = propTypes;
Review.defaultProps = defaultProps;

export default Review
