import styled from 'styled-components';


//justify-content organiza os itens de forma horizontal
//align-item organiza os itens de forma vertifical
export const Card = styled.div`

    width:95%;

    padding: 5px;
    margin-bottom: 5px;
    
    background-color: #5588c9;

    border-radius:5px;
    color:white;

    @media(min-width: 900px){
        width:800px;
    }
    
`;

export const Content = styled.div`
    transition: height ease 0.5s;
    overflow: hidden;

    text-align: justify;
    text-justify: inter-word;

    background-color:#23599e;

    padding-left: 20px;
    padding-right: 20px;
    
 

    border-radius:5px;

`;


export const Title = styled.button`
    width: 100%
    background-color: transparent;
    font-size: 20px;
    border: none
    color:white;
    cursor:pointer;
    display:flex;
    justify-content: space-between;
    text-align: left;
    text-justify: inter-word;
`;

export const Arrow = styled.img`
    width: 25px;
    height: 25px;
`;