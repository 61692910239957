import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import TextField from "../formComponents/TextField/index";

const propTypes = {
    waiting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    couponShowSuccessMessage: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired
};

const defaultProps = {};

class CouponForm extends Component {
    state = {
        couponCode: null,
        showForm: true,
        selectedPlan: {}
    };

    constructor(props) {
        super(props);
        this.textFieldContainer = React.createRef();
    }

    showCouponFormHandler = (e) => {
        e.preventDefault();

        this.setState({
            showForm: true
        })
    };

    btnValidateHandler = (e) => {
        e.preventDefault();

        this.props.onValidate(this.textFieldContainer.current.state.value);
    };

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('cc');

        if (code !== null) {
            this.setState({
                couponCode: code.toUpperCase()
            }, () => {
                this.props.onValidate(code.toUpperCase());
                window.addEventListener('load', () => window.location.href = '#planos');
            });
        }
    }

    render() {

        const textState = <div className="text-container">
            Você possui um coupon de desconto? Clique <a href="#" onClick={ this.showCouponFormHandler }>aqui</a>{' '}
            para informar.
        </div>;

        const formState = <div className="form-container">

            <p>
                Insira o código do cupom no campo abaixo,
                clique em validar e selecione o plano elegível para o código informado. <br/><br/>
            </p>

            <TextField
                ref={this.textFieldContainer}
                type="text"
                label="Informe aqui código do cupom"
                name="coupon"
                value={this.state.couponCode || ''}
                waiting={this.props.waiting}
                pipe={ v => v.toUpperCase() }
                isValid={!this.props.invalid}
                feedbackText="Cupom inválido"
            />
            <button className="btn-validate" onClick={this.btnValidateHandler}>Validar</button>

        </div>;

        return (
            <div className="coupon-form">
                { this.state.showForm === false && textState }
                { this.state.showForm === true && formState }
            </div>
        );
    }
}

CouponForm.propTypes = propTypes;
CouponForm.defaultProps = defaultProps;

export default CouponForm;
