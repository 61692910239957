import React from 'react';
import { FloatButton } from './style';

const FloatActionButton = ({children, linkTo = '#'}) => {

    return (
        <FloatButton href = {linkTo} target = "_blank" >
            {children}
        </FloatButton>
    );
}

export default FloatActionButton;