import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextField from "../TextField/index";
import * as moment from 'moment';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    feedbackText: PropTypes.string,
    mask: PropTypes.string,
    required: PropTypes.bool
};

const defaultProps = {
    mask: '+55 (11) 11111-1111',
    feedbackText: 'Telefone inválido',
    required: false,
    onChange: () => {}
};

class PhoneField extends Component {
    state = {
        isValid: true
    };

    render() {
        return (
            <TextField
                type="tel"
                label={this.props.label}
                name={this.props.name}
                mask={this.props.mask}
                feedbackText={this.props.feedbackText}
                isValid={this.state.isValid}
                onChange={this.props.onChange}
                required={this.props.required}
            />
        );
    }
}

PhoneField.propTypes = propTypes;
PhoneField.defaultProps = defaultProps;

export default PhoneField;