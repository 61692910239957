import React from 'react';
import {Content} from './style';
import players from '../../assets/Players.jpeg';

const Players = () => {

    return (
        <Content>
            <div className = "centralized ">
                <img src={players} alt="Players" />
            </div>
        </Content>
    );
}

export default Players;