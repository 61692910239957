import React from 'react';
import {Container, LogoImage} from "./styles";

export default function Logo(props) {
    const {
        className,
        link,
        colored
    } = props;

    return (
        <Container className={className}>
            <a href={link}><LogoImage colored={colored} /></a>
        </Container>
    )
}