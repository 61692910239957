import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from "../../formComponents/TextField/index";
import DateField from "../../formComponents/DateField/index";
import EmailField from "../../formComponents/EmailField/index";
import PhoneField from "../../formComponents/PhoneField/index";
import NumberOnlyField from "../../formComponents/NumberOnlyField/index";
import CpfField from "../../formComponents/CpfField/index";
import DateFieldFree from '../../formComponents/DateFieldFree/index';

const propTypes = {
    minor: PropTypes.bool,
    show: PropTypes.bool.isRequired
};

const defaultProps = {
    minor: false,
    isValid: false
};

class IdentityFree extends Component {
    state = {
        minor: this.props.minor
    };
    
    requiredFields = () => {

        const fields = {
            name: 'Nome Completo',
            email: 'E-mail',
            birthday: 'Data de Nascimento',
            phone: 'Telefone (celular)',
            document: 'CPF',
        };

        if (this.props.minor) {
            fields.guardian_name = 'Nome do Responsável';
            fields.guardian_document2 = 'RG';
            fields.guardian_document2_issuer = 'Órgão Emissor';
            fields.guardian_document2_shipping_date = 'Data de emissão';
        }

        return fields;
    };

    values = {};

    handleOnChange = (e, isValid) => {
        this.values[e.target.name] = {
            value: e.target.value,
            isValid: isValid
        };
    };

    /**
     * Called via Ref property.
     * Check if data is valid.
     */
    validate = () => {
        let invalidFields = {};
        let validFields = {};

        const object = Object.keys(this.requiredFields());

        object.forEach((k) => {
            if (this.values.hasOwnProperty(k) && this.values[k].isValid) {
                validFields[k] = this.requiredFields()[k];
            } else {
                invalidFields[k] = this.requiredFields()[k];
            }
        });

        return {
            isValid: Object.keys(validFields).length === object.length,
            fields: {
                valid: validFields,
                invalid: invalidFields
            },
            values: this.values
        };
    };

    render() {
        const style = {
            display: this.props.show ? '' : 'none'
        };

        // adult fields...
        const adultFields = <div>

            <div className="linha">
                <div className="w-5">
                    <TextField
                        type="text"
                        label="Nome Completo (igual a identidade)"
                        name="name"
                        onChange={this.handleOnChange}
                        required={true}
                    />
                </div>

                <div className="w-5">
                    <EmailField
                        name="email"
                        label="E-mail (será usado para comunicações)"
                        onChange={this.handleOnChange}
                        onBlur={this.handleOnChange}
                        required={true}
                    />
                </div>
            </div>

            <div className="linha">
                <div className="w-4">
                    <CpfField
                        label="CPF (será usado como login)"
                        required={true}
                        onChange={this.handleOnChange}
                    />
                </div>

                <div className="w-3">
                    <DateFieldFree
                        name="birthday"
                        label="Data de Nascimento"
                        onChange={this.handleOnChange}
                        required={true}
                    />
                </div>

                <div className="w-3">
                    <PhoneField
                        name="phone"
                        label="Telefone (celular)"
                        onChange={this.handleOnChange}
                        required={true}
                    />
                </div>
            </div>
        </div>;

        // minor fields...
        const minorFields = <div>
            <div className="linha">
                <div className="w-7">
                    <TextField
                        type="text"
                        label="Nome Completo (igual a identidade)"
                        name="name"
                        onChange={this.handleOnChange}
                        required={true}
                    />
                </div>

                <div className="w-3">
                    <DateField
                        name="birthday"
                        label="Data de Nascimento"
                        onChange={this.handleOnChange}
                        required={true}
                    />
                </div>
            </div>

            <div className="titulo-bloco">Dados do Responsável Legal</div>

            <div className="linha">
                <div className="w-7">
                    <TextField
                        type="text"
                        label="Nome Completo (igual a identidade)"
                        name="guardian_name"
                        onChange={this.handleOnChange}
                        required={true}
                    />
                </div>

                <div className="w-3">
                    <DateField
                        name="guardian_birthday"
                        label="Data de Nascimento"
                        onChange={this.handleOnChange}
                        required={true}
                    />
                </div>
            </div>

            <div className="linha">
                <div className="w-7">
                    <EmailField
                        name="email"
                        label="E-mail (será usado para comunicações)"
                        onChange={this.handleOnChange}
                        onBlur={this.handleOnChange}
                        required={true}
                    />
                </div>

                <div className="w-3">
                    <PhoneField
                        name="phone"
                        label="Telefone (celular)"
                        onChange={this.handleOnChange}
                        required={true}
                    />
                </div>
            </div>

            <div className="linha">
                <div className="w-3">
                    <CpfField
                        label="CPF do Responsável (será usado como login)"
                        required={true}
                        onChange={this.handleOnChange}
                    />
                </div>

                <div className="w-3">
                    <NumberOnlyField
                        name="guardian_document2"
                        label="RG do Responsável"
                        required={true}
                        onChange={this.handleOnChange}
                    />
                </div>

                <div className="w-2">
                    <TextField
                        type="text"
                        label="Órgão emissor"
                        name="guardian_document2_issuer"
                        maxLength={10}
                        required={true}
                        onChange={this.handleOnChange}
                        pipe={(v) => v.toUpperCase()}
                    />
                </div>

                <div className="w-2">
                    <DateField
                        label="Data de Emissão"
                        name="guardian_document2_shipping_date"
                        required={true}
                        onChange={this.handleOnChange}
                    />
                </div>
            </div>
        </div>;

        return (
            <div className="formulario-artista" style={style}>
                <div className="titulo-bloco">Dados do Artista</div>
                { this.props.minor ? minorFields : adultFields }
            </div>
        );
    }
}

IdentityFree.propTypes = propTypes;
IdentityFree.defaultProps = defaultProps;

export default IdentityFree;
