import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextField from "../TextField/index";

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    feedbackText: PropTypes.string,
    tabIndex: PropTypes.number,
    maxLength: PropTypes.number,
    pipe: PropTypes.func,
    isValid: PropTypes.bool
};

const defaultProps = {
    required: false,
    tabIndex: 0,
    onChange: () => {
    },
    maxLength: -1,
    pipe: e => e
};

class NumberOnlyField extends Component {
    state = {
        isValid: this.props.isValid,
        value: ''
    };

    /**
     * Handle onChange.
     *
     * @param e
     * @param isValid
     */
    handleOnChange = (e, isValid) => {
        this.props.onChange(e, isValid);
    };

    render() {
        return (
            <TextField
                type="tel" // hacking to show a numeric keyboard to the user.
                label={this.props.label}
                name={this.props.name}
                feedbackText={this.props.feedbackText}
                isValid={this.props.isValid}
                value={this.state.value}
                onChange={this.handleOnChange}
                numberOnly={true}
                required={this.props.required}
                tabIndex={this.props.tabIndex}
                maxLength={this.props.maxLength}
                pipe={this.props.pipe}
            />
        );
    }
}

NumberOnlyField.propTypes = propTypes;
NumberOnlyField.defaultProps = defaultProps;

export default NumberOnlyField;