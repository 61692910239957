import React, { Component } from 'react';
import {Link} from 'react-router-dom';

//Style
import './style.css'

// Images e Assets
import iconFacebook from '../../assets/logoFace.png'
import iconInsta from '../../assets/logoIg.png'
import iconYoutube from '../../assets/logoYt.png'
import logoFooter from '../../assets/ooppah-logo-color.png'
import iconLinkedin from '../../assets/logoLi.png'



const Rodape = (props) => {
    
    const location = window.location.pathname;
  
    const decideRouteLink = (to) => {
        switch (to) {
            case 'inicio':
                return location === '/' ? "#": process.env.REACT_APP_HOME + "#";

            case 'plataforma':
                return location === '/' ? "#plataforma": process.env.REACT_APP_HOME + "#plataforma";
 
            case 'produtores':
                return location === '/' ? "#produtores": process.env.REACT_APP_HOME + "#produtores";

            case 'artista':
                return location === '/' ? "#artista": process.env.REACT_APP_HOME + "#artista";

            case 'ooppahplay':
                return location === '/' ? "#ooppahplay": process.env.REACT_APP_HOME + "#ooppahplay";

            case 'planos':
                return location === '/' ? "#planos": process.env.REACT_APP_HOME + "#planos";

            case 'depoimentos':
                return location === '/' ? "#depoimentos": process.env.REACT_APP_HOME + "#depoimentos"; 

            case 'faq':
                return process.env.REACT_APP_HOME + "/faq";  
        }
    }
    
    return (
        <div className="footer">
            <div className="footer-logo">
                <a href={decideRouteLink('inicio')}><img src={logoFooter} alt = "Logo ooppah"/></a>
            </div>
            <div className="footer-nav">
                <ul>
                    <li><a href={ decideRouteLink('inicio')}>INÍCIO</a></li>
                    <li><a href={ decideRouteLink('plataforma')}>A PLATAFORMA</a></li>
                    <li><a href={ decideRouteLink('produtores')}>PRODUTORES</a></li>
                    <li><a href={ decideRouteLink('artista')}>ARTISTAS</a></li>
                    <li><a href={ decideRouteLink('ooppahplay')}>OOPPAH PLAY</a></li>
                    <li><a href={ decideRouteLink('depoimentos')}>DEPOIMENTOS</a></li>
                    <li><Link to="/faq">FAQ</Link></li>
                    <li><a href="https://docs.ooppah.com/terms-of-use" >termo de uso</a></li>
                    <li><a href="https://www.ooppah.com.br/contato" >fale conosco</a></li>
                </ul>
            </div>
            <div className="footer-social">
                <a href="https://www.facebook.com/ooppahoficial/" target="_blank" rel="noopener"><img src={iconFacebook} alt = "facebook icon" /></a>
                <a href="https://instagram.com/ooppahoficial/" target="_blank" rel="noopener"><img src={iconInsta} alt = "Instagram icon" /></a>
                <a href="https://www.youtube.com/channel/UCS0-zxv6IIpXHe_ExfEVpTw" target="_blank" rel="noopener"> <img src={iconYoutube} alt = "Youtube Icon" /></a>
                <a href="https://www.linkedin.com/company/ooppah" target="_blank" rel="noopener"> <img src={iconLinkedin} alt = "Linkedin Icon" /></a>
            </div>
            <div className="footer-copy">
                <p>OOPPAH SOLUCOES ARTISTICAS LTDA | CNPJ 23.825.830/0001-53</p>
                <p>Copyright © 2013 - 2018. Todos os direitos reservados.</p>
            </div>
        </div>
    );
    
}

export default Rodape;
