import React from 'react';
import { Container } from './styles';

export default function Nav({ className, children }) {
    return (
        <Container className={className}>
            {children}
        </Container>
    );
};

export const NavItem = ({className, href, target = '_parent', children}) => {
    return (
        <li className={className}>
            <a href={href} target={target}>{children}</a>
        </li>
    );
};