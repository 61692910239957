import React, { Component } from 'react'
import Swiper from 'swiper/dist/js/swiper.min.js';
import 'swiper/dist/css/swiper.min.css';
import axios from 'axios';

// Style
import './style.css';

// Images e Assets
import produtoraPrev from '../../assets/produtores-prev.png';
import produtoraNext from '../../assets/produtores-next.png';

// Data
import produtores from '../../assets/producers/producers.json';

export default class Produtores extends Component {

    state = {
        slideItems: []
    };


    componentDidMount() {
        
        let items = [];

        produtores.map((el, index) => {
            items.push(
                <div key={index} className="swiper-slide">
                    <div className="slide-cont">
                        <div className="produtor">
                            <div className="avatar">
                                <img src={require('../../assets/producers/' + el.folder + '/avatar.jpg')} />
                            </div>
                            <div className="nome">
                                {el.name}
                            </div>
                        </div>
                        <div className="producoes">
                            {
                                [1, 2, 3, 4].map((item, j) => (
                                    <div key={j} className="producao">
                                        <img src={require('../../assets/producers/' + el.folder + '/thumbs/' + item + '.jpg')} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            );
        });
        
        this.setState({slideItems: items}, () => {
            const swiperProdutores = new Swiper('.produtores-slide', {
                slidesPerView: 1,
                spaceBetween: 20,
                navigation: {
                    nextEl: '.produtores-next',
                    prevEl: '.produtores-prev'
                }
            });

            swiperProdutores.update()
        });
    }
    
    render() {
        return (
            <div id="produtores" className="produtores-section">
                <div className="produtores-topo">
                    <h2>Seja visto. Seja Ooppah!</h2>
                    <p>Grandes produtores de elenco e pesquisa aberta ao mercado.</p>
                </div>
                
                <div className="container">
                    <div className="produtores-slide swiper-container">
                        <div className="swiper-wrapper">
                            {this.state.slideItems}
                        </div>
                    </div>

                    <div className="produtores-slide-nav">
                        <div className="produtores-prev"><img src={produtoraPrev} /></div>
                        <div className="produtores-next"><img src={produtoraNext} /></div>
                    </div>
                </div>
            </div>
        )
    }
}
    