import styled from 'styled-components';


//justify-content organiza os itens de forma horizontal
//align-item organiza os itens de forma vertifical
export const Container = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    background-color: #f5f3f0;
    margin: 10px 20px;

    border-style:solid;
    border-color:#d1cec9;
    border-width: 1px;
    border-radius: 5px;

    padding-top:5px;

    @media(min-width: 900px){
        width:835px;
        margin-left: auto;
        margin-right: auto;
    }
`;