import React from 'react';
import { Container, Icon } from "./style";
import XIcon from '../../../assets/x.svg'

const Item = ({item}) => {

    return (
        <Container isActivated = {item.isActivated}>
            <Icon src={ item.isActivated ? item.icon : XIcon}/>
            <span dangerouslySetInnerHTML={{__html: item.name}}></span>
        </Container>
    );
}

export default Item;