import styled from 'styled-components';

export const Container = styled.div`
    background-color: #f7f7f7;
`;

export const Title = styled.h1`
    font-size:35px;
    font-weight:bold;
    text-align:center;
`;

export const Paragraph = styled.p`
    margin-top:15px;
    text-align:justify;
`;

export const TextArea = styled.div`
    max-width:900px;
    padding-left:20px;
    padding-right:20px;
    margin-left:auto;
    margin-right:auto;
`;

export const SubTitle = styled.h2`
    font-size:20px;
    font-weight:bold;
    margin-top:15px;
    text-align:center;
`;

export const UnorderedList = styled.ul`
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
`;

export const ListItem = styled.li`
    list-style-type: disc;
`;

export const BannerImage = styled.div`
    position:relative;
    width:fit-content;
    height:fit-content;
    margin-left: auto;
    margin-right: auto;

    a:hover {
        cursor: pointer;
    }

    
`;


export const ImageArea = styled.div`
    margin-top:10px;
`;

export const Image = styled.img`
    margin-bottom:10px;
    border-radius:20px;
`;

export const PDFLinkLeft = styled.a`
    position:absolute;
    color: white;
    background-color: #0fb50d;
    border-radius:5px;
    padding: 5px 10px;
    bottom:30px;
    left:40px;
    @media(min-width: 700px){
        bottom:100px;
        left:100px;
    }

    &:hover {
        background-color:#129611;
    }
`;

export const PDFLinkRight = styled.a`
    position:absolute;
    color: white;
    background-color: #0fb50d;
    border-radius:5px;
    padding: 5px 10px;
    bottom:30px;
    right:40px;
    @media(min-width: 700px){
        bottom:100px;
        right:100px;
    }

    &:hover {
        background-color:#129611;
    }
`;

export const MainTextWrapper = styled.div`
    border-width: 2px;;
    border-style: solid;
    border-color:#ffa947;
    border-radius:10px;
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top:10px;
    padding: 10px 5px;

`;