import React, { useState } from "react";
import Card from "../Card";

import { AnswerTextArea, Header, PreAnswerArea, PreAnswerGroup } from "../style";

const OpenResponse = ({question, updateAnswer}) => {
    const [answer, setAnswer] = useState("");

    const onChange = (event) => {
        setAnswer(event.target.value);
        let answer = {
            id: question.id,
            type: question.type,
            answer: event.target.value
        }
        updateAnswer(question, answer);
    };

    return (
        <Card>
            <Header>
                {question.text}
            </Header>

            <PreAnswerArea>
                <PreAnswerGroup>
                    <AnswerTextArea placeholder= "Escreva Aqui" value = {answer} onChange={onChange}/>
                </PreAnswerGroup>
            </PreAnswerArea>
        </Card>
    );
}

export default OpenResponse;