import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    plan: PropTypes.string.isRequired,
    next: PropTypes.func.isRequired,
    current: PropTypes.number.isRequired,
    coupon: PropTypes.object,
    step1: PropTypes.func,
    step2: PropTypes.func,
    step3: PropTypes.func
};

const defaultProps = {
    step1: () => {},
    step2: () => {},
    step3: () => {}
};

class Footer extends Component {

    handleNext = (e) => {
        this.props.next(e);
    };

    handleStep1 = (e) => {
        this.props.step1(e);
    };

    handleStep2 = (e) => {
        this.props.step2(e);
    };

    handleStep3 = (e) => {
        this.props.step3(e);
    };

    render() {
        return (
            <div className="rodape">
                <div className="etapa">
                    <div
                        className={this.props.current === 1 ? 'numero ativo' : 'numero'}
                        onClick={this.handleStep1}
                    >1</div>

                    <div
                        className={this.props.current === 2 ? 'numero ativo' : 'numero'}
                        onClick={this.handleStep2}
                    >2</div>

                    <div
                        className={this.props.current === 3 ? 'numero ativo' : 'numero'}
                        onClick={this.handleStep3}
                    >3</div>
                </div>

                <div className="plano-selecionado">
                    <div className="topo-titulo">Plano selecionado</div>
                    <div className="destaque">{this.props.plan}</div>
                </div>

                {
                    this.props.coupon !== null && this.props.coupon.hasOwnProperty('id') && (
                        <div className="plano-selecionado">
                            <div className="topo-titulo">Cupom de desconto</div>
                            <div className="destaque">{this.props.coupon.code}</div>
                        </div>
                    )
                }

                {
                    this.props.current === 3 &&
                    <div className="plano-termos">
                        <label>
                            Ao clicar em "Concluir" assumo concordar com os &nbsp;
                            <a href="https://docs.ooppah.com/terms-of-use" target="_blank">
                                termos de uso do OOPPAH.
                            </a>
                        </label>
                    </div>
                }

                <div className="btn" onClick={this.handleNext}>
                    { this.props.current ===3 ? 'Concluir' : 'Avançar'}
                </div>
            </div>
        );
    }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer
