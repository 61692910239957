import React, { useEffect } from 'react';
import Menu from '../../components/menu';
import MenuBackground from '../../components/MenuBackground';
import LogoWp from '../../assets/LogoWP.png';
import Rodape from '../../containers/Rodape';
import FloatActionButton from '../../components/FloatActionButton';
import ActCodeAndRegistrationFree from '../../containers/ActCodeAndRegistrationFree';
const FreeRegistration = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div>
            <MenuBackground>
                <Menu isFaq={true} isStatic= {true}/>
            </MenuBackground>
            <ActCodeAndRegistrationFree />
            <Rodape />
            <FloatActionButton linkTo = "https://wa.me/5521994765872">
                <img src = {LogoWp} />
            </FloatActionButton>
        </div>

    );
}

export default FreeRegistration;