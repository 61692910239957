import styled, {css} from 'styled-components';
import whiteLogo from "../../assets/logo.png";
import coloredLogo from '../../assets/logo-cor.png'

export const Container = styled.div`
    display: block;
    @media(min-width: 1080px) {
        margin-bottom: 0
    }
`;

export const LogoImage = styled.img.attrs(props => ({
    src: props.colored ? coloredLogo : whiteLogo,
    alt: 'Plataforma Ooppah'
}))`
    
`;