import React, {useState, useRef, useEffect} from 'react';
import DOMpurify from 'dompurify';

import {Card, Content, Title, Arrow} from './style';
import './style.css';

import ArrowUp from '../../assets/arrow-up.png';
import ArrowDown from '../../assets/arrow-down.png';

const FAQPost = ({post}) => {

    const [isOpen, setIsOpen] = useState(false);
    const content = useRef(null); //react hooks para pegar referencia de objetos.

    const decideContentStyle = () => {
        return isOpen? {height: content.current.scrollHeight + 10}: {height: 0};
    }

    const decideArrowImg = () => {
        return isOpen? ArrowUp:ArrowDown;
    }

    const handleOnClick = (event) => {
        event.preventDefault();
        let currentIsOpen = isOpen;
        setIsOpen(!currentIsOpen);
    }


    return (
        <Card>
            <Title onClick = {handleOnClick}>
                {post.title}
                <Arrow src = {decideArrowImg()} alt = "arrow-down"/>
            </Title>
            
            <Content id = "content" ref = {content} style = {decideContentStyle()}>
                <div className = 'basic-css' dangerouslySetInnerHTML={{ __html: DOMpurify.sanitize(post.text)}}>
                </div>
            </Content>
        </Card>
    );
}

export default FAQPost;