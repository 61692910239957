import { createApolloFetch } from 'apollo-fetch';

class Fetch {

    static obj = null;

    /**
     * Initialize a apollo fetch.
     */
    static createObj = () => {
        Fetch.obj = createApolloFetch({
            uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
        });
    };

    /**
     * Set Apollo header middleware.
     */
    static setMiddleware = () => {
        const tokenInfo = JSON.parse(sessionStorage.getItem('ooppah_user_token'));

        Fetch.obj.use(({ request, options }, next) => {
            if (! options.headers) {
                options.headers = {};  // Create the headers object if needed.
            }

            if (tokenInfo) {
                options.headers['Authorization'] = tokenInfo.type + '' + tokenInfo.token;
            }

            next();
        });
    };

    /**
     * Initialize.
     * @returns {Fetch}
     */
    static initialize = () => {
        Fetch.createObj();
        Fetch.setMiddleware();
        return Fetch;
    }
}

export default Fetch.initialize();