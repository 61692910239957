import styled, {css} from 'styled-components';
import VideoComponent from '../../components/Video';

export const Container = styled.div`
    background-color: #23599e;
    position: relative;
    display: flex;
    flex-direction: column;
    .video {
        order: 2;
        margin-bottom: -6px;
        @media(min-width: 1080px) {
            order: 1;
        }
        video {
            width: 100%;            
        }
    }
`;

export const Video = styled(VideoComponent)``;

export const Description = styled.div`
    order: 3;
    margin: 0 auto;
    max-width: 1230px;
    padding-left: 30px;
    padding-right: 30px;
    @media(min-width: 1080px) {
        position: absolute;
        top: 250px;
        left: 0;
        right: 0;
    }
`;

export const DescriptionTitle = styled.h1`
    max-width: 470px;
    color: white;
    font-size: 36px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    line-height: 42px;
    &:after {
        background-color: #e78724;
        bottom: 0;
        content: '';
        display: block;
        height: 5px;
        left: 0;
        position: absolute;
        width: 37%;
    }
    
    @media(min-width: 1080px) {
        text-shadow: 2px 2px 10px #333;
        font-size: 43px;
        margin-bottom: 30px;
        padding-bottom: 40px;
        line-height: inherit;
    }
`;

export const DescriptionText = styled.p`
    max-width: 470px;
    color: white;
    font-size: 18px;
    margin-bottom: 30px;
    text-shadow: 2px 2px 3px #333;
    text-align: justify;
    text-justify: inter-word;
`;

export const BlackFridayBanner = styled.img`
    margin-bottom: 25px;

    @media(min-width: 1080px){
        max-width: 220px; 
        
    }
    @media(min-width: 1300px){
        max-width: 330px;        

    }
`;

export const OoppahName = styled.span`
    font-size: 56px;
    color: #e87f07;
`;