import styled from 'styled-components';


export const ContainerForm = styled.div`
    padding-top: 30px;
    margin-bottom: 50px;
    
    a {
      font-weight: 600;
      color: dodgerblue;
    }
`;

export const Container = styled.div`
    width: 100%;
    margin: 20px auto 0 auto;
    @media (min-width: 1080px) {
        width: 50%;
    }
    p {
        text-align: center;
    }
    .form-input {
        width: 85%;
        float: left;
        input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-right-width: 0 !important;
        }
        .input input {
            border: 1px solid #3b599a;
        }
    }

    input[type="text"]{
        text-align: center;
        border-color: #ccc;
    }

    .label{
        width: 96%;
        text-align: center;
        top: 23px;
    }

    .form-input .error-msg {
        font-size: 16px; 
        display: block;
        text-align: center;
        margin-top: 30px;
    }

    .planos-tipo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .btn {
            background-color: #ffffff;
            border-style: solid;
            border-width: 1px;
            border-color: black;
            border-radius: 40px;
            cursor: pointer;
            font-size: 10px;
            margin: 10px;
            padding: 15px 50px;
            text-transform: uppercase;
            @media (min-width: 1080px){
                font-size: 12px;
            }
            &.btn-adulto{
                background-color: #3b599a;
                color: white;
                text-align: center;
                border-color: black;
            }
            &.btn-mirim{
                background-color: #e78724;
                color: white;
                text-align: center;
                border-color: black;
            }
        } 
    }

`;

export const Button = styled.button`
    width: 15%;
    padding: 18px 10px;

    border: 1px solid #3b599a;
    font-size: 11px;
    border-right-width: 0 !important;
    background: #3b599a;
    color: #fff;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    cursor: pointer;
`;