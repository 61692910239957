import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: ${props => props.isActivated ? '#131F30':'#BCBCBC'};
`;

export const Icon = styled.img`
    width: 17px;
    height: 17px;
    margin-right: 23px;

`;