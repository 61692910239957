import React, { Component } from 'react';
import Identity from "./Identity";
import Plans from "../Plans/index";

import './style.css';
import Address from "./Address/index";
import FlashMessage from "../FlashMessage/index";
import Footer from "./Footer/index";
import Payment from "./Payment/index";
import Review from "./Review/index";
import Spinner from "../Spinner/index";
import MinorAlert from "../MinorAlert/index";

const propTypes = {};

const defaultProps = {};

class RegisterForm extends Component {
    constructor (props) {
        super(props);
        this.identityComponent = React.createRef();
        this.plansComponent = React.createRef();
        this.addressComponent = React.createRef();
        this.paymentMethodComponent = React.createRef();
    }

    

    state = {
        minor: false,
        selectedPlan: {},
        flashShow: false,
        flashMessage: {},
        current: 1,
        formData: {},
        flashWithCustomErrors: false
    };

    changeForm = (changeTo='adult') => {
        if(changeTo=='adult') { // exibe o form para adultos
            if (this.state.minor) { // esta com o minor selecionado
                this.setState({
                    minor: !this.state.minor,
                    selectedPlan: {}
                }, () => this.plansComponent.current.loadPlans(this.state.minor));
            }
        }
        
        if(changeTo=='minor') { // exibe o form para minor
            if (!this.state.minor) { // nao esta com o minor selecionado
                this.setState({
                    minor: !this.state.minor,
                    selectedPlan: {}
                }, () => this.plansComponent.current.loadPlans(this.state.minor));
            }
        }
    };

    handleValidate = (e) => {
        // step 1...
        const identityComponentValidation = this.identityComponent.current.validate();
        const addressComponentValidation = this.addressComponent.current.validate();

        let isValid = identityComponentValidation.isValid && addressComponentValidation.isValid;

        this.setState({
            flashShow: !isValid,
            flashMessage: {
                ...identityComponentValidation.fields.invalid,
                ...addressComponentValidation.fields.invalid
            }
        });

        if (this.state.current === 1 && isValid) {
            e.preventDefault();

            this.setState({
                current: 2
            });
        }

        // step 2...
        if (this.state.current === 2) {
            // step 1...
            const paymentComponentValidation = this.paymentMethodComponent.current.validate();
            isValid = paymentComponentValidation.isValid;

            if (this.state.selectedPlan.payment_method === 'C') {
                this.setState({
                    flashShow: !isValid,
                    flashMessage: {
                        ...identityComponentValidation.fields.invalid,
                        ...addressComponentValidation.fields.invalid,
                        ...paymentComponentValidation.fields.invalid
                    }
                });
            }

            if (isValid) {
                this.setState({
                    formData: {
                        ...identityComponentValidation.values,
                        ...addressComponentValidation.values,
                        ...paymentComponentValidation.values
                    }
                }, () => {
                    console.log(this.state.formData);

                    this.setState({
                        current: 3
                    });
                });
            }
        }

        // step 3...
        // Register new subscription...
        if (this.state.current === 3) {
            this.setState({
                current: 4
            });

            let data = {};

            Object.keys(this.state.formData).forEach((item) => {
                data[item] = this.state.formData[item].value
            });

            data['plan_id'] = this.state.selectedPlan.id;
            data['payment_method'] = this.state.selectedPlan.payment_method;
            data['type'] = this.state.minor ? 'minor' : 'major';

            if (this.state.selectedPlan.coupon !== null && this.state.selectedPlan.coupon.hasOwnProperty('id')) {
                data['coupon_code'] = this.state.selectedPlan.coupon.code;
            }

            if(this.state.selectedPlan.payment_method == "C") {
                const card = window.PagSeguro.encryptCard({
                    publicKey: process.env.REACT_APP_ENCRYPT_PAGSEGURO,
                    holder: data['cc_holder_name'],
                    number:data['cc_number'],
                    expMonth:data['cc_expiration_month'],
                    expYear: data['cc_expiration_year'],
                    securityCode: data['cc_security_code']
                });

                data['cc_encrypted_card'] = card.encryptedCard;
            }


            console.log('Ooppah :: Registering... ');

            fetch(`${process.env.REACT_APP_API_REGISTER}/register`, {
                method: 'post',
                body: JSON.stringify(data),
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => response.json().then(text => ({
                json: text,
                meta: response
            })))
            .then(({json, meta}) => {

                console.log(meta, json);

                if (meta.status !== 201) {
                    let errors = {};

                    Object.keys(json.errors).forEach(item => {
                        // console.log(item, json.errors[item][0]);
                        errors[item] = json.errors[item][0]
                    });

                    this.setState({
                        flashShow: true,
                        flashMessage: errors,
                        flashWithCustomErrors: true,
                        current: 1
                    });
                } else {
                    this.setState({current: 5});
                }
                // console.log('JSON:', json);
                // console.log('Meta:', meta);
            })
        }
    };

    changeStep1 = e => {
        this.setState({current: 1});
    };

    handlePlanOnChange = (selectedPlan) => {
        
        this.setState({
            selectedPlan: selectedPlan
        }, () => !this.state.selectedPlan.hasOwnProperty('id'))
    };

    render() {
        const className = ['planos-form'];

        if (this.state.minor) {
            className.push('minor');
        } else {
            className.push('adulto');
        }

        return (
            <div>
                <a name="plans" />

                <div id="planos" className="planos-section">
                    <div className="planos-topo">
                        <h2>Crie agora seu perfil</h2>
                        <p>Não espere os testes caírem do céu, seja dono do seu futuro. Esteja em evidência. Cadastre-se!</p>
                    </div>
                    <div className="container">

                        <form action="?" method="POST">

                            {
                                this.state.current <= 3 &&
                                <div className="planos-tipo">
                                    <div className={!this.state.minor ? "btn btn-adulto" : "btn"} onClick={() => this.changeForm('adult')}>
                                        Artista adulto
                                    </div>
                                    <div className={this.state.minor ?  "btn btn-mirim" : "btn"} onClick={() => this.changeForm('minor')}>
                                        Artista mirim
                                    </div>
                                </div>
                            }

                            { this.state.minor !==false && this.state.selectedPlan.length && <MinorAlert /> }

                            <div className={ className.join(' ') }>
                                <FlashMessage
                                    show={this.state.flashShow}
                                    withErrors={true}
                                    customError={this.state.flashWithCustomErrors}
                                    content={this.state.flashMessage} />

                                {
                                    this.state.current === 4 &&
                                    <div align="center" style={{paddingTop: '100px', paddingBottom: '50px'}}>
                                        <h1 style={{fontSize: '24px'}}>
                                            <Spinner show={true} />
                                            &nbsp; &nbsp;Cadastrando, aguarde...
                                        </h1>
                                    </div>
                                }

                                {
                                    this.state.current === 5 &&
                                    <div align="center" style={{paddingTop: '100px', paddingBottom: '50px'}}>
                                        <h1 style={{fontSize: '24px'}}>
                                            Obrigado, seu cadastro foi realizado com sucesso.
                                            Verifique a sua caixa de e-mail
                                        </h1>
                                    </div>
                                }

                                {
                                    !this.state.selectedPlan.hasOwnProperty('id') &&
                                    <Plans ref={this.plansComponent} onChange={this.handlePlanOnChange} planCode={this.props.planCode}/>
                                }

                                {
                                    this.state.selectedPlan.hasOwnProperty('id') &&
                                    this.state.selectedPlan.id !== '' &&
                                    <div>
                                        <Identity
                                            ref={this.identityComponent}
                                            minor={this.state.minor}
                                            show={this.state.current === 1}
                                        />

                                        <Address
                                            ref={this.addressComponent}
                                            show={this.state.current === 1}
                                        />

                                        <Payment
                                            ref={this.paymentMethodComponent}
                                            show={this.state.current===2}
                                            method={this.state.selectedPlan.payment_method}
                                        />

                                        {
                                            this.state.current === 3 &&
                                            <Review
                                                plan={this.state.selectedPlan}
                                                coupon={this.state.selectedPlan.coupon}
                                                minor={this.state.minor}
                                                data={this.state.formData}
                                            />
                                        }

                                        {
                                            this.state.current <= 3 &&
                                            <Footer
                                                plan={this.state.selectedPlan.name}
                                                coupon={this.state.selectedPlan.coupon}
                                                current={this.state.current}
                                                step1={this.changeStep1}
                                                next={this.handleValidate}
                                            />
                                        }
                                    </div>
                                }
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

RegisterForm.propTypes = propTypes;
RegisterForm.defaultProps = defaultProps;

export default RegisterForm;
