import React from 'react';
import {Container, LogoImage} from "./styles";
import logoPlay from './../../assets/logoStore.png'
import logoPlayWhite from './../../assets/logoStore_white.png'

export default function Logo(props){
    const {
        className,
        link,
        title,
        target,
        isFaq = false
    } = props;

    return (
        <Container className={className}>
            <a target={target} href={link} title={title}>
                <LogoImage src={isFaq ? logoPlayWhite : logoPlay} alt="Ooppah Store" rel="noopener"/>
            </a>
        </Container>
    )
}