import React, { Component } from 'react'
import Modal from '../../components/modal';

// Style
import './style.css'

// Images e Assets
import tvOoppah from '../../assets/play-tv.png'
import logoPLAY from '../../assets/ooppahplay.png'
import play from '../../assets/iconPlayRed.png'

export default class TvOoppah extends Component {
    constructor(){
        super();

        this.state = {
            video: '',
            modal: false
        }
    }

    startVideo = () => {
        this.setState({
            video: "https://www.youtube.com/embed/sZIUn2Z-rw4?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1",
            modal: true
        })
    }
    
    endVideo = () => {
        this.setState({
            video: '',
            modal: false
        })
    }

    render() {
        return (
            <div id="ooppahplay" className="tv-ooppah">
                <div className="conteudo">
                    <div className="imagem">
                        <img src={tvOoppah} />
                    </div>
                    <div className="texto">
                        <div className="topo-tv">
                            <img className= "image-size" src={logoPLAY} />
                        </div>
                        <h2 className="titulo">
                            É tipo o Netflix do Artista
                        </h2>
                        <p>Artistas cadastrados no Ooppah têm acesso a dicas, entrevistas, palestras e cursos exclusivos.</p>
                        <div className="btn-play">
                            <div className="icon"><img src={play} alt="play" /></div>
                            <div className="text" onClick={() => window.open('https://ooppah.com.br/tv', '_blank')}>Acessar Agora</div>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.modal} close={() => this.endVideo()}>
                    <div className="video-cont">
                        <iframe src={this.state.video} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
                    </div>
                </Modal>
            </div>
        )
    }
}
