import styled from "styled-components";

export const Container = styled.div`
    width: ${props => props.squareWidth};

    border-width: 1px;
    border-style: solid;
    border-color: black;
    position: relative;
    cursor: pointer;

    &:after {
        content: "";
        display:block;
        padding-bottom: 100%;
    }
    
`;

export const Icon = styled.img`
    width: 30px;
    height: 30px;
`;

export const Content = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ThreePointIcon = styled.img`
    position: absolute;
    width: 6px;
    height: 12px;
    bottom: 2px;
    right: 2px;
`;