import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberOnlyField from "../../formComponents/NumberOnlyField/index";
import TextField from "../../formComponents/TextField/index";
import {MoipValidator} from '../../../Utils/Moip';
import boleto from '../../../assets/boleto.png';

const propTypes = {
    show: PropTypes.bool.isRequired,
    method: PropTypes.oneOf(['C', 'B'])
};

const defaultProps = {
    method: 'C'
};

class Payment extends Component {
    /**
     * Component states.
     *
     * @type {{ccIsValid: boolean}}
     */
    state = {
        ccIsValid: false
    };

    /**
     * Date Object.
     *
     * @type {Date}
     */
    date = new Date();

    /**
     * Fields values.
     *
     * @type {{}}
     */
    values = {};

    /**
     * Component onChange Handler.
     *
     * @param e
     * @param isValid
     */
    handleOnChange = (e, isValid) => {
        this.values[e.target.name] = {
            value: e.target.value,
            isValid: isValid
        };
    };

    /**
     * NumberField onChange handler.
     * @param e
     * @param isValid
     */
    handleNumberOnChange = (e, isValid) => {
        const _isValid = MoipValidator.isValidNumber(e.target.value);

        this.setState({
            ccIsValid: _isValid
        });

        this.handleOnChange(e, _isValid);
    };

    /**
     * MonthField month.
     * @param e
     * @param isValid
     */
    handleMonthOnChange = (e, isValid) => {
        const value = e.target.value;

        const _isValid = value > 0 && value < 13;

        this.setState({
            monthIsValid: _isValid
        });

        this.handleOnChange(e, _isValid);
    };

    /**
     * YearField Handler.
     *
     * @param e
     * @param isValid
     */
    handleYearOnChange = (e, isValid) => {
        const value = e.target.value;

        const _isValid = value >= this.date.getFullYear();

        this.setState({
            yearIsValid: _isValid
        });

        this.handleOnChange(e, _isValid);
    };

    handleCodeOnChange = (e, isValid) => {
        const value = e.target.value;

        const _isValid = value.length >= 3;

        this.setState({
            codeIsValid: _isValid
        });

        this.handleOnChange(e, _isValid);
    };

    /**
     * Fields that should be validated if selected payment method is 'B'.
     *
     * @returns {{cc_number: string, cc_expiration_month: string, cc_expiration_year: string, cc_holder_name: string}}
     */
    requiredFields = () => {
        return {
            cc_number: 'Número do Cartão de Crédito',
            cc_expiration_month: 'Mês de expiração do cartão de crédito',
            cc_expiration_year: 'Ano de expiração do cartão de crédito',
            cc_holder_name: 'Nome impresso no cartão',
            cc_security_code: 'Código de segurança do cartão',
        };
    };

    /**
     * Called via Ref property.
     * Check if data is valid.
     */
    validate = () => {
        let invalidFields = {};
        let validFields = {};

        const object = Object.keys(this.requiredFields());

        object.forEach((k) => {
            if (this.values.hasOwnProperty(k) && this.values[k].isValid) {
                validFields[k] = this.requiredFields()[k];
            } else {
                invalidFields[k] = this.requiredFields()[k];
            }
        });

        if (this.props.method === 'B') {
            return {
                isValid: true
            };
        }

        return {
            isValid: Object.keys(validFields).length === object.length,
            fields: {
                valid: validFields,
                invalid: invalidFields
            },
            values: this.values
        };
    };

    render() {

        const boletoForm = <div className="pagamento">
            <div className="topo-pagamento">
                <div className="pagamento-titulo">BOLETO BANCÁRIO</div>
                <p className="pagamento-texto">Para o plano selecionado, você efetuará pagamento através de boleto bancário.</p>
            </div>
            <div className="image-pagamento">
                <img src={boleto} />
            </div>
            <div className="pagamento-texto">Prossiga com seu cadastro e fique tranquilo.</div>
            <div className="pagamento-texto">Nós vamos prepara-lo e você o receberá por e-mail.</div>
        </div>;

        const ccForm = <div className="pagamento">
            <div className="topo-pagamento">
                <div className="pagamento-titulo">INFORMAÇÕES DE COBRANÇA</div>
                <p className="pagamento-texto">Informe o cartão de crédito que será utilizado na sua assinatura.</p>
            </div>

            <div className="linha">
                <NumberOnlyField
                    label="Número do Cartão"
                    name="cc_number"
                    isValid={this.state.ccIsValid}
                    required={true}
                    onChange={this.handleNumberOnChange}
                    feedbackText="Cartão de crédito inválido."
                />
            </div>

            <div className="linha">
                <div className="w-5">
                    <NumberOnlyField
                        name="cc_expiration_month"
                        label="Mês de Vencimento"
                        isValid={this.state.monthIsValid}
                        maxLength={2}
                        required={true}
                        onChange={this.handleMonthOnChange}
                        feedbackText="Mês inválido"
                    />
                </div>
                <div className="w-5">
                    <NumberOnlyField
                        name="cc_expiration_year"
                        label="Ano de Vencimento"
                        isValid={this.state.yearIsValid}
                        maxLength={4}
                        required={true}
                        onChange={this.handleYearOnChange}
                        feedbackText="Ano inválido"
                    />
                </div>
            </div>

            <div className="linha">
                <TextField
                    type="text"
                    label="Nome impresso no cartão"
                    name="cc_holder_name"
                    required={true}
                    pipe={v => v.toUpperCase()}
                    onChange={this.handleOnChange}
                />
            </div>

            <div className="linha">
                <div className="w-5">
                    <NumberOnlyField
                        name="cc_security_code"
                        label="Código de segurança"
                        isValid={this.state.codeIsValid}
                        maxLength={3}
                        required={true}
                        onChange={this.handleCodeOnChange}
                        feedbackText="Código inválido"
                    />
                </div>
            </div>
        </div>;

        return (
            <div className="formulario-artista">
                {
                    this.props.show && (this.props.method === 'B' ? boletoForm : ccForm)
                }
            </div>
        );
    }
}

Payment.propTypes = propTypes;
Payment.defaultProps = defaultProps;

export default Payment;
