import styled, { css } from 'styled-components';


export const PreAnswerArea = styled.div`
`;

export const Header = styled.header`
    width: 100%;
    border-width: 0px 0px 2px 0px;
    border-style:solid;
    border-color: #c9c9c9;
`;

export const PreAnswerGroup = styled.div`
    display: flex;
    align-items: center;
    
    input {
        margin-right: 7px;
    }
`;

export const AnswerTextArea = styled.textarea`
    width:100%;
    height: 150px;
`;