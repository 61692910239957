import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {Container, Main, Title, Header, Description, Questions, FinishButton} from './style';
import axios from 'axios';
import Rodape from "../../containers/Rodape";
import FloatActionButton from "../../components/FloatActionButton";
import Menu from "../../components/menu";
import MenuBackground from "../../components/MenuBackground";
import LogoWp from '../../assets/LogoWP.png';
import SingleResponse from "../../components/Questions/SingleResponse";
import Card from "../../components/Questions/Card";
import MultipleResponse from "../../components/Questions/MultipleResponse";
import OpenResponse from "../../components/Questions/OpenResponse";


const Survey = () => {
    let { id } = useParams();

    const [survey, setSurvey] = useState({
        id: "",
        title:"",
        description:"",
        questions: []
    });

    const [answers, setAnswers] = useState({});

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isSurveyShown, setIsSurveyShown] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);

    const loadSurvey = async (id) => {
        let apiUrl = `${process.env.REACT_APP_API_REGISTER}/survey?id_survey_answer=${id}`;
        try{
            let response = await axios.get(apiUrl);
            setSurvey(response.data.data);
            prepareState(response.data.data.questions);
            setIsSurveyShown(true);
            setIsButtonDisabled(false);
        }catch(e){
            setIsSurveyShown(false);
            
            console.log(e);
            
            //TODO Implementar em caso de erro, 404 é porque o survey Answer não existe OU ja foi respondido
        }
    }
    //Prepara dinamicamente o array de respostas baseado nas questions recebidas do backend
    const prepareState = (questions) => {
        let answers = {id: id};
        questions.forEach( (question) => {
            switch (question.type) {
                case "1":
                    //Esta forma cria dinamicamente um novo atributo no objeto !!Importante!!
                    answers[question.id] = {
                        id: question.id,
                        type: question.type,
                        answer: "",
                    };
                    break;
                
                case "2":
                    answers[question.id] = {
                        id: question.id,
                        type: question.type,
                        answer: [],
                    };
                    break;
            
                case "3":
                    answers[question.id] = {
                        id: question.id,
                        type: question.type,
                        answer: "",
                    };
                    break;
                default:
                    break;
            }
            setAnswers(answers);
        });
    }

    const updateAnswer = (question, answer) => {
        let id = question.id;
        setAnswers({
            ...answers,
            [id]: answer
        });
    }

    const sendSurveyAnswers = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true);

        const headers = {'Content-Type': 'application/json'}
        axios.post(
            `${process.env.REACT_APP_API_REGISTER}/survey`,
            answers,
            {headers: headers}
        )
        .then((response) => {
            setIsSuccess(true);
        })
        .catch((error) => {
            console.log(error);
        });

    }

    useEffect(()=>{
        //Faz a requisição
        loadSurvey(id);
    }, []);

    return(
        <Container>
            <Helmet>
                <title>Ooppah! Questionário</title>
                <meta name='description' content="Questionário para saber opinião dos artistas Ooppah e trazer melhorias para nossa plataforma"/>
                <meta property="og:title" content="Ooppah! Questionário"/>
                <meta property="og:description" content="Questionário para saber opinião dos artistas Ooppah e trazer melhorias para nossa plataforma" />
            </Helmet>
            
            <MenuBackground>
                <Menu isFaq={true} isStatic= {true}/>
            </MenuBackground>

            <Main>
                <Card>
                    {!isSurveyShown && <Title>Questionário já respondido</Title>}
                    <Title>{survey.title}</Title>
                    <Description>{survey.description}</Description>
                </Card>

                <Questions>
                    {survey.questions.map( (question) => {

                        switch (question.type) {
                            case "1":
                                return (
                                    <SingleResponse key = {question.id} question = {question} updateAnswer = {updateAnswer} otherId = {survey.other_id} />
                                );
                                break;
                            case "2":
                                return (
                                    <MultipleResponse key = {question.id} question = {question} updateAnswer = {updateAnswer} />
                                );
                                break;
                            case "3":
                                return (
                                    <OpenResponse key = {question.id} question = {question} updateAnswer = {updateAnswer} />
                                );
                                break;
                            default:
                                break;
                        }

                        
                    })}
                </Questions>
                
                {
                    isSuccess &&
                    <p style={{color: 'green'}}>
                        Questionário respondido, obrigado !
                    </p>
                }
                
                <FinishButton disabled = {isButtonDisabled} onClick = {sendSurveyAnswers} >
                    Concluir
                </FinishButton>
            </Main>

            <Rodape />
            <FloatActionButton linkTo = "https://api.whatsapp.com/send?1=pt_BR&phone=+5521993330503">
				<img src = {LogoWp} alt="Logo Whatsapp" rel="noopener" />
			</FloatActionButton>
        </Container>
    );
}

export default Survey;