import React from 'react';

import PrevArrow from '../../assets/ArrowPrev.png';
import NextArrow from '../../assets/ArrowNext.png';

import {Container, Ul, PageButton, PrevNextButton, ArrowImg, ThreeDots} from './style';

const Pagination = ({pagination, handlePageChange, handlePageNext, handlePagePrev}) => {
    
    const {current_page, total_pages} = pagination;
    

    const buttons = [];
    //Precisamos pegar somente (SE EXISTIR) 3 numeros antes e 3 numeros depois da pagina atual, se houver mais,
    //adicionar 3 pontinhos entre os números (...);
    //unshift no começo, e push no final
    buttons.push(current_page); //adicionamos a página atual
    //se existir páginas anteriores a atual, adicionar até o limite de 3 itens.
    let atualPage = current_page - 1;
    for(let i = 3; i > 0; i--){
        if(atualPage < 1) {break;}
        buttons.unshift(atualPage);
        atualPage--;
    }

    atualPage = current_page + 1;
    for(let i = 0; i < 3; i++){
        if(atualPage > total_pages) {break;}
        buttons.push(atualPage);
        atualPage++;
    }

    //é Importante adicionar a primeira pagina da lista se ela não estiver no array.
    // entre os botões e o primeiro da lista tem que existir (...)
    if(buttons[0] !== 1){ 
        buttons.unshift('...');
        buttons.unshift(1);
    }

    //adicionar ... no final se o ultimo item do array não for o total_pages
    if(buttons[buttons.length - 1] !== total_pages){
        buttons.push('...');
    }

    const decideButtonEvidence = (isCurrentPage) => {
        return isCurrentPage? {fontWeight: 'bold', backgroundColor: '#d17000'}: {};
    }
    
    return (
        <Container>
            <Ul>
                <li key = 'previous'>
                    <PrevNextButton onClick = {handlePagePrev}>
                        <ArrowImg src = {PrevArrow} />
                    </PrevNextButton>
                </li>
                {buttons.map( (pageNumber) => {
                    let isCurrentPage = false;
                    if(pageNumber === current_page){
                        isCurrentPage = true;
                    }
                    return (
                        <li key = {pageNumber}>
                            {
                                typeof pageNumber === 'number'
                                &&
                                <PageButton
                                    onClick = {() => {handlePageChange(pageNumber)}}
                                    style = {decideButtonEvidence(isCurrentPage)}
                                >
                                    {pageNumber}
                                </PageButton>
                            }
                            {
                                typeof pageNumber !== 'number'
                                &&
                                <ThreeDots>
                                    {pageNumber}
                                </ThreeDots>
                            }
                            
                        </li>
                    );
                })}
                <li key = 'next'>
                    <PrevNextButton onClick = {handlePageNext}>
                        <ArrowImg src = {NextArrow} />
                    </PrevNextButton>
                </li>
            </Ul>  
        </Container>
    );
}

export default Pagination;