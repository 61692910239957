import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Menu from "../../components/menu";
import MenuBackground from "../../components/MenuBackground";
import { Arrow, AttentionWrapper, Benefits, BenefitsContent, BenefitsDesktop, BenefitsIcon, BenefitsItems, BenefitsText, BestPlan, BlackWeekIcon, Container, CrossContainer, CrossIcon, Curtain, Esgotado, EsgotadoText, Footer, Header, HeaderRightSide, Icon, LinkFreeButton, LinkPremiumButton, LinkSpecialButton, Main, PercentIcon, Plan, PlanContent, PlansContainer, PlansContainer2, PremiumBenefits, PremiumButton, PremiumSubtitle, PremiumTitle, PromoIcon, SolanoPhoto, SolanoPhotoHalf, SuperPremiumButton, Text, TextFreeContent, Theme, Top } from "./style";
import Swiper from 'swiper/dist/js/swiper.min.js';
import 'swiper/dist/css/swiper.min.css';
import { SuccessCases } from '../../Utils/Constants';

import Solano from '../../assets/solano_shadow.png';
import SolanoHalf from '../../assets/solano_shadow-half.png';
import CardArtist from "../../components/CardArtist";

import ArrowLeft from '../../assets/seta_opacidade.png';
import ArrowRight from '../../assets/seta_preenchida.png';
import IconSearch from '../../assets/lupa.svg';
import IconShare from '../../assets/share.svg';
import IconFolder from '../../assets/pasta.svg';
import IconAdvancedSearch from '../../assets/busca_especial.svg';
import IconStatistics from '../../assets/estatistica.svg';
import IconClub from '../../assets/clube.svg';
import Benefit from "./Benefit";
import BenefitDesktop from "./BenefitDesktop";

import Warning from '../../assets/atencao.svg';

import {freePlan, lightPlan, premiumPlan, vipPlan} from '../../Utils/Constants';
import Item from "./Item";

import Modal from '../../components/Modal';
import { Link } from "react-router-dom";
import Cross from '../../assets/logoUnCheck.png';
//import Percent50 from '../../assets/50percent.png';
//import Percent60 from '../../assets/60percent.png';
//import Percent70 from '../../assets/70percent.png';
//import BlackWeek from '../../assets/blackweeklogo.png'
import Blackooppah from '../../assets/blackooppah.png'
import ChristmasCampaign from '../../assets/campanha_natal_g.gif';

const Plans = () => {

    const benefits1 = [
        {
            id: 1,
            icon: IconSearch,
            text: 'Perﬁl disponível para <b>pesquisa pública</b>'
        },
        {
            id: 2,
            icon: IconShare,
            text: 'Link com perﬁl pessoal <b>personalizado</b> e <b>compartilhável</b>'
        },
        {
            id: 3,
            icon: IconFolder,
            text: 'Acesso a Dicas, Notícias e <b>testes de elenco</b> do mercado'
        }
    ];

    const benefits2 = [
        {
            id: 4,
            icon: IconAdvancedSearch,
            text: '<b>Destaque</b> nas pesquisas de elenco'
        },
        {
            id: 5,
            icon: IconStatistics,
            text: 'Contador de <b>pesquisa</b> e <b>visitas</b> ao seu perﬁl'
        },
        {
            id: 6,
            icon: IconClub,
            text: 'Até <b>50% OFF</b> em empresas parceiras do Ooppah Clube'
        }
    ];
    
    const [sliderItem, setSliderItem] = useState([]);

    const [isOpen, setIsOpen] = useState(false);

    const [benefitNumber, setBenefitNumber] = useState(0);

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleOpen = (benefit) => {
        setIsOpen(true);
        setBenefitNumber(benefit);
    }

    const getBenefit = (benefitId) => {
        if(benefitId === 0){
            return;
        }
        return benefitId > 3 ? benefits2[benefitId - 4]: benefits1[benefitId - 1];
    }

    
    useEffect(() => {
        window.scrollTo(0, 0);
        const arr = [];
        SuccessCases.map((artist, index) => {
            arr.push(
                <div key={index} className="swiper-slide">
                    <div className="slide-cont">
                        <CardArtist artist={artist} />
                    </div>
                </div>   
            );
        });
        setSliderItem(arr);
    }, []);

    useEffect(() => {
        const successSwiper = new Swiper('.success-slide', {
            slidesPerView: 3,
            spaceBetween: 5,
            navigation: {
                nextEl: '.success-next',
                prevEl: '.success-prev'
            },
            breakpoints:{
                850:{
                    slidesPerView: 2,
                }
            }
        });
    }, [sliderItem]);

   
    return (
        <Container>
            <Helmet>
                <title>Ooppah! Planos</title>
                <meta name='description' content="Observe todos os beneficios do Ooppah Premium"/>
                <meta property="og:title" content="Ooppah! Planos"/>
                <meta property="og:description" content="Observe todos os beneficios do Ooppah Premium" />
            </Helmet>

            <MenuBackground>
                <Menu isFaq={true} isStatic= {true}/>
            </MenuBackground>

            <Main>
                <Header>

                    <SolanoPhoto src = {Solano} />
                    
                    <HeaderRightSide>
                        <PremiumTitle>Seja <span style={{color: "#ff7e05"}}>Premium</span></PremiumTitle>
                        <PremiumSubtitle>Acredite em seu talento e aproveite <br></br> todo potencial de ser Ooppah </PremiumSubtitle>
                        <div className="container">
                            <div className="success-slide swiper-container">
                                <div className="swiper-wrapper">
                                    {sliderItem}
                                </div>
                            </div>
                            <div className="success-prev"><Arrow src = {ArrowLeft} /> </div>
                            <div className="success-next"><Arrow src = {ArrowRight} /></div>
                        </div>
                    </HeaderRightSide>
                </Header>
                {/*
                    <Theme>Recursos pra quem é <b>Ooppah Premium</b></Theme>
                */}
                <PremiumBenefits>
                    <SolanoPhotoHalf src = {SolanoHalf} />
                    {/*
                    <Benefits>
                        <BenefitsContent>
                            {
                                benefits1.map((benefit, index) => {
                                    return (
                                        <Benefit key={index} benefit = {benefit} width = {"30%"} onClick = {() => {handleOpen(benefit.id)}}/>
                                    );
                                })
                            }
                        </BenefitsContent>
                            
                        <BenefitsContent>
                            {
                                benefits2.map((benefit, index) => {
                                    return (
                                        <Benefit key={index} benefit = {benefit} width = {"30%"} onClick = {() => {handleOpen(benefit.id)}}/>
                                    );
                                })
                            }
                        </BenefitsContent>
                    </Benefits>
                    
                    
                    <BenefitsDesktop>
                        
                        {
                            benefits1.map((benefit, index) => {
                                return (
                                    <BenefitDesktop key={index} benefit = {benefit}/>
                                );
                            })
                        }
                        {
                            benefits2.map((benefit, index) => {
                                return (
                                    <BenefitDesktop key={index} benefit = {benefit}/>
                                );
                            })
                        }
                        
                    </BenefitsDesktop>
                */}
                </PremiumBenefits>
                <BestPlan>Escolha seu <br></br> melhor plano</BestPlan>

                <PlansContainer2>
                    <Plan>
                        <div>
                            <Top>
                                <Text size={"1.1em"}>
                                   Plano <b>Light</b>
                                </Text>
                            </Top>
                            <PlanContent>
                                {
                                    lightPlan.map((item, index) => {
                                        return (
                                            <Item key = {index} item = {item} />
                                        );
                                    })   
                                }
                            </PlanContent>
                        </div>

                        <Footer>
                            {/*<BlackWeekIcon src={Blackooppah} /> */}
                            <Text size = {'20px'}> <b>Plano Mensal</b> </Text>
                            <Text> R$ <span style={{fontSize: 23, fontWeight: 'bold'}}>19,00</span>/mês</Text>
                            <Text>ou</Text>
                            {/* <Text><CrossContainer><PercentIcon src = {Percent50} /><CrossIcon src={Cross} /><span style={{fontSize: 23, fontWeight: 'bold'}}>30%</span></CrossContainer> no <b>Plano Anual</b></Text>*/}
                            {/*<Text><CrossContainer><CrossIcon src={Cross} /><span style={{fontSize: 23, fontWeight: 'bold'}}>30%</span></CrossContainer> no <b>Plano Anual</b></Text> */}
                            <Text><span style={{fontSize: 23, fontWeight: 'bold'}}>30%</span> no <b>Plano Anual</b></Text>
                            {/*<Text color="red">De <span style={{fontWeight: "bold"}}>R$159</span> por <span style={{fontWeight: "bold"}}>R$99</span></Text> */}
                            <LinkPremiumButton to={'/registration/LIGHT'}>Selecionar</LinkPremiumButton>
                            {/*<Text color="red" style={{fontSize: 14, fontWeight:"bold"}}>*Enquanto sua assinatura estiver ativa</Text> */}
                            <Text color="red">Se você ja é ou foi Ooppah, <a target = "_blank" href='https://account.ooppah.com'>Clique Aqui</a></Text>
                        </Footer>
                        {/* 
                        <Esgotado>
                            ESGOTADO
                        </Esgotado>
                        <EsgotadoText>
                            Aguarde novas vagas ou inscreva-se no Plano Gratuito. 
                            <a href="https://forms.gle/4Qk1zHqGg2cdiijEA"> Entre na lista de espera clicando aqui</a>
                        </EsgotadoText>
                        
                        <Curtain></Curtain>
                        */}
                    </Plan>

                    <Plan>
                        <div>
                            <Top>
                                <Text size={"1.1em"}>
                                   Plano <b>Premium</b>
                                </Text>
                            </Top>
                            <PlanContent>
                                {
                                    premiumPlan.map((item, index) => {
                                        return (
                                            <Item key = {index} item = {item} />
                                        );
                                    })   
                                }
                            </PlanContent>
                        </div>

                        <Footer>
                            {/*<BlackWeekIcon src={Blackooppah} /> */}
                            <Text size = {'20px'}> <b>Plano Mensal</b> </Text>
                            <Text> R$ <span style={{fontSize: 23, fontWeight: 'bold'}}>34,00</span>/mês</Text>
                            <Text>ou</Text>
                            {/*<Text><CrossContainer><PercentIcon src = {Percent60} /><CrossIcon src={Cross} /><span style={{fontSize: 23, fontWeight: 'bold'}}>40%</span></CrossContainer> no <b>Plano Anual</b></Text> */}
                            {/*<Text><CrossContainer><CrossIcon src={Cross} /><span style={{fontSize: 23, fontWeight: 'bold'}}>40%</span></CrossContainer> no <b>Plano Anual</b></Text>*/}
                            <Text><span style={{fontSize: 23, fontWeight: 'bold'}}>40%</span> no <b>Plano Anual</b></Text>
                            {/*<Text color="red">De <span style={{fontWeight: "bold"}}>R$408</span> por <span style={{fontWeight: "bold"}}>R$199</span></Text>*/}
                            <LinkPremiumButton to={'/registration/PREMIUM'}>Selecionar</LinkPremiumButton>
                            {/*<Text color="red" style={{fontSize: 14, fontWeight:"bold"}}>*Enquanto sua assinatura estiver ativa</Text>*/}
                            <Text color="red">Se você ja é ou foi Ooppah, <a target = "_blank" href='https://account.ooppah.com'>Clique Aqui</a></Text>
                        </Footer>
                        {/* 
                        <Esgotado>
                            ESGOTADO
                        </Esgotado>
                        <EsgotadoText>
                            Aguarde novas vagas ou inscreva-se no Plano Gratuito
                            <a href="https://forms.gle/4Qk1zHqGg2cdiijEA"> Entre na lista de espera clicando aqui</a>
                        </EsgotadoText>
                        <Curtain></Curtain>
                        */}
                    </Plan>

                </PlansContainer2>
                <PlansContainer2>

                    <Plan>
                        <div>
                            <Top>
                                <Text size={"1.1em"}>
                                   Plano <b>Vip</b>
                                </Text>
                            </Top>
                            <PlanContent>
                                {
                                    vipPlan.map((item, index) => {
                                        return (
                                            <Item key = {index} item = {item} />
                                        );
                                    })   
                                }
                            </PlanContent>
                        </div>
                        <Footer>
                            <Text size = {'20px'}> <b>Plano Mensal</b> </Text>
                            <Text> R$ <span style={{fontSize: 23, fontWeight: 'bold'}}>68,00</span>/mês</Text>
                            <Text>ou</Text>
                            {/* <Text size={"0.8em"}> <b>Promoção de Natal OOPPAH***</b></Text>*/}
                            {/*<PromoIcon src={ChristmasCampaign}/>*/}
                            {/*<Text><CrossContainer><PercentIcon src = {Percent70} /><CrossIcon src={Cross} /><span style={{fontSize: 23, fontWeight: 'bold'}}>50%</span></CrossContainer> no <b>Plano Anual</b></Text>*/}
                            {/*<Text><CrossContainer><CrossIcon src={Cross} /><span style={{fontSize: 23, fontWeight: 'bold'}}>50%</span></CrossContainer> no <b>Plano Anual</b></Text>*/}
                            <Text><span style={{fontSize: 23, fontWeight: 'bold'}}>50%</span> no <b>Plano Anual</b></Text>
                            <LinkSpecialButton to={'/registration/VIP'}><b>Sim, eu mereço!</b></LinkSpecialButton>
                            <Text color="red">Se você ja é ou foi Ooppah, <a target = "_blank" href='https://account.ooppah.com'>Clique Aqui</a></Text>
                        </Footer>
                        {/* 
                        <Esgotado>
                            ESGOTADO
                        </Esgotado>
                        <EsgotadoText>
                            Aguarde novas vagas ou inscreva-se no Plano Gratuito
                            <a href="https://forms.gle/4Qk1zHqGg2cdiijEA"> Entre na lista de espera clicando aqui</a>
                        </EsgotadoText>
                        <Curtain></Curtain>
                        */}
                    </Plan>

                    <Plan>
                        <div>
                            <Top>
                                <Text size={"1.1em"}>
                                    Plano <b>Gratuito</b>
                                </Text>
                            </Top>
                            <PlanContent>
                                {
                                    freePlan.map((item, index) => {
                                        return (
                                            <Item key = {index} item = {item} />
                                        );
                                    })   
                                }
                            </PlanContent>
                        </div>

                        <Footer>
                            <Text size = {'20px'}> <b>Gratuito</b> </Text>
                            <Text> com direito a</Text>
                            <Text><b>15 dias</b> de</Text>
                            <Text>acesso Premium</Text>
                            <LinkFreeButton 
                                to={'/freeregistration'} >
                                    Selecionar
                            </LinkFreeButton>
                        </Footer>
                    </Plan>
                    {/*
                    <Plan>
                        <div>
                            <Top>
                                <Text size={"1.1em"}>
                                    <b>Gratuito</b>
                                </Text>
                            </Top>
                            <PlanContent>
                               <Text>Pacote básico apenas com <br></br> recursos gratuitos:</Text>
                               <div style={{marginBottom: '50px'}}></div>
                               <TextFreeContent>Perﬁl disponível para pesquisa pública;</TextFreeContent>
                               <TextFreeContent>Link com perﬁl pessoal personalizado e compartilhável;</TextFreeContent>
                               <TextFreeContent>Acesso a Dicas, Notícias e testes de elenco do mercado</TextFreeContent>
                            </PlanContent>
                        </div>

                        <Footer>
                            <Text size = {'20px'}> <b>Gratuito</b> </Text>
                            <Text> com direito a</Text>
                            <Text><b>15 dias</b> de</Text>
                            <Text>acesso Premium</Text>
                            <PremiumButton><Link style={{color: 'white', width:'100%', display:'inline-block'}} to = "/freeregistration"> Selecionar </Link></PremiumButton>
                        </Footer>
                    </Plan>
                    */}
                </PlansContainer2>
            </Main>
            <div style={{marginBottom: '50px'}}></div>
            <Footer>
                <AttentionWrapper>
                    <Icon src = {Warning}/>
                    <Text size={"1.3em"}>
                        &nbsp; ATENÇÃO: O Ooppah <b>não é uma agência</b> e <b>não cobra repasses</b> por trabalhos fechados.
                    </Text>
                </AttentionWrapper>

                <Text size={"1.0em"} margin={"5px"}>
                    A assinatura do perfil premium não garante seleção, testes e/ou escalação em trabalhos. 
                </Text>

                <Text size={"1.0em"} margin={"5px"}>
                    *A critério do assinante, contatos telefônicos podem ser disponibilizados
                    apenas para pesquisadores verificados pela plataforma.
                </Text>

                <Text size={"1.0em"}>
                    **Em todas as modalidades de pagamento dos <b>plano mensais</b>, os descontos são
                    válidos apenas a partir do 6º mês. Descontos concedidos uma vez por produto.
                </Text>
                
            </Footer>

            <div style={{marginBottom: '50px'}}></div>

            <Modal isOpen={isOpen} handleClose = {handleClose}>
                <div style={{display: "flex", alignItems: 'center', flexDirection: 'column'}}>
                    <Icon src = {getBenefit(benefitNumber) ? getBenefit(benefitNumber).icon: null} />
                    <div dangerouslySetInnerHTML={{__html:getBenefit(benefitNumber) ? getBenefit(benefitNumber).text : ""}}>

                    </div>
                </div>
            </Modal>
        </Container>
    );
}

export default Plans;