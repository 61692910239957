import styled from 'styled-components';

export const Container = styled.div`
    background-color: #f9f6f4;
    display: block;
    padding: 50px 0 0;
    width: 100%;
    @media (min-width: 1080px) {
        padding: 100px 0 80px 0;
    }
`;

export const ContainerTop = styled.div`
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 30px;
    
    h2 {
        color: #616061;
        font-size: 42px;
        display: block;
        width: 100%;
        font-weight: 300;
        @media (min-width: 1080px){
            font-size: 62px;
        }
    }
    p{
        color: #616061
        font-size: 18px
    }

    .profile-type {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .btn {
            background-color: #ffffff;
            border-style: solid;
            border-width: 1px;
            border-color: black;
            border-radius: 40px;
            cursor: pointer;
            font-size: 10px;
            margin: 10px;
            padding: 15px 50px;
            text-transform: uppercase;
            @media (min-width: 1080px){
                font-size: 12px;
            }
            &.btn-free{
                background-color: #3b599a;
                color: white;
                text-align: center;
                border-color: black;
            }
            &.btn-premium{
                background-color: #e78724;
                color: white;
                text-align: center;
                border-color: black;
            }
        }
    }
`;
export const MainContent = styled.div`
    display: block;
    margin: auto;
    max-width: 1230px;
    padding: 10px 30px;
    width: 100%;
    @media (min-width: 1080px){
        padding: 30px;
    }

    .formulario-artista {
        display: block;
        width: 100%;

        .titulo-bloco {
            color: #3b599a;
            font-size: 14px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 5px;
            text-transform: uppercase;
        }

        .linha {
            align-items: stretch;
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            flex-flow: wrap;
            @media (min-width: 1080px) {
                flex-flow: nowrap;
            }

            .w-1 {
                width: 100%;
                @media (min-width: 1080px) {
                    width: 8%;
                }
            }
            
            .w-2 {
                width: 100%;
                @media (min-width: 1080px) {
                    width: 18%;
                }
            }
                
            .w-3 {
                width: 100%;
                @media (min-width: 1080px) {
                    width: 28%;
                }    
            }
               
            .w-4 {
                width: 100%;
                @media (min-width: 1080px){
                    width: 38%;
                }
            }
                
            .w-5 {
                width: 100%;
                @media (min-width: 1080px) {
                    width: 48%;
                }
            }
                
            .w-6 {
                width: 100%;
                @media (min-width: 1080px) {
                    width: 58%;
                } 
            }
                
            .w-7 {
                width: 100%;
                @media (min-width: 1080px) {
                    width: 68%;
                }
                    
            }

        }

        .registro-assinatura {
            display: block;
            width: 100%;

            .registro-assinatura-topo {
                display: block;
                margin-bottom: 30px;
                text-align: center;

                .assinatura-topo-titulo{
                    font-size: 17px;
                    font-weight: 700;
                    color: #3b599a;
                }
                    
                .assinatura-topo-texto {
                    font-size: 14px;
                    text-align: center;
                }
                    
            }

            .registro-assinatura-cont {
                display: block;
                width: 100%;

                .linha {
                    align-items: stretch;
                    display: flex;
                    flex-flow: row wrap;
                }

                .bloco {
                    display: block;
                    margin-bottom: 10px;
                    width: 100%;

                    &.bloco-3 {
                        display: block;
                        width: 100%;

                        @media (min-width: 1080px) {
                            width: 30%;
                        }
                            
                    }

                    .bloco-topo {
                        color: #3b599a;
                        font-size: 17px;
                        font-weight: 700;
                        margin-bottom: 5px;
                        text-transform: uppercase;
                    }
                       
                    .bloco-cont {
                        border: 1px solid #cdcbca;
                        border-radius: 5px;
                        display: block;
                        padding: 20px;
                        width: 100%;

                        &.laranja {
                            background-color: #3b599a;
                            color: white;
                        }

                        .selecionado {
                            align-items: center;
                            color: white;
                            display: flex;
                            flex-flow: column nowrap;
                            justify-content: center;
                            text-align: center;

                            h2{
                                font-size: 17px;
                                font-weight: 700;
                                text-transform: uppercase;
                            }
                                
                            p{
                                font-size: 14px;
                            }
                        }

                        .bloco-cont-linha {
                            display: flex;
                            width: 100%;
                            justify-content: space-between;
                            flex-flow: row wrap;

                            .texto {
                                font-size: 14px;
                                margin-bottom: 10px;
                                .sinal {
                                    color: #3b599a;
                                }       
                            }

                            .bloco-column{
                                display: block;
                                margin-bottom: 15px;
                                width: 100%;
                                @media (min-width: 1080px) {
                                    width: 48%;
                                }

                                .bloco-titulo {
                                    font-size: 16px;
                                    color: #cdcbca;
                                    display: block;
                                    margin-bottom: 5px;
                                    text-transform: uppercase;
                                    width: 100%;
                                }
                                    
                                .texto-label {
                                    font-size: 10px;
                                }
                                    
                                &.bloco-column-last {
                                    width: 100%;
                                }
                                    
                            }
                                
                                
                        }
                            
                            
                    }
                        
                       
                }
                    
                    
            }
                
                
        }
            
    }

    .central-button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
        
`;


export const Button = styled.button
`
    cursor: pointer;
    background-color: #3b599a;
    border-radius: 40px;
    border: 1px solid #3b599a;
    font-size: 17px;
    padding: 5px 5px;
    text-transform: uppercase;
    color: #FFF;
    text-align: center;
    width: 20%;
    @media (min-width: 1080px){
        width: 20%;
    }
    :disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;

export const PlanTerms = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    max-width: 500px;
    align-items: center;
    margin-bottom: 15px;
    
    label {
        margin-left: 10px;
        color: #666;
    }

    a {
        color: #666;
        text-decoration: underline;
    }
`;

export const ContentButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const RadioButtonArea = styled.div`
    display: flex;
    justify-content: center;
`;

export const InputRadio = styled.input`

`;

export const Label = styled.label`
    padding: 0px 5px;
`;

export const ButtonFreeAccont = styled.button`
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: #3b599a;
    border-radius: 40px;
    border: 1px solid #3b599a;
    font-size: 17px;
    padding: 5px 5px;
    color: #FFF;
    text-align: center;
    width: 20%;
    justify-content: center;
    @media (min-width: 1080px){
        width: 20%;
    }
    

`;
