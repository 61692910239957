import React, { Component } from 'react'

// Style
import './style.css'

export default class Modal extends Component {
    render() {
        return (
            <div className={this.props.open ? "modal open" : "modal"}>
                <div className="modal-content">
                    {this.props.children}
                </div>
                <div className="close-modal" onClick={this.props.close}>X</div>
            </div>
        )
    }
}
