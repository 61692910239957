import React from 'react';

import TextField from '../formComponents/TextField';
import iconSearch from '../../assets/iconSearch.png';

import {BtnSearch, Container, IconSearch} from './style';


const FAQSearch = ({keyword, handleKeywordChange, handleSearch}) => {

    return (
        <Container>
            <TextField 
                type="text"
                label="Pesquise por palavras chave"
                name="keyword"
                value={keyword || ''}
                onChange = {handleKeywordChange}
            />
            <BtnSearch onClick = {handleSearch} ><IconSearch src = {iconSearch} alt = "Pesquisar" /></BtnSearch>
        </Container>
    );
    
}

export default FAQSearch;