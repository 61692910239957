import React from 'react';

import {Container, Spinner, Icon} from './style';
import LoadingIcon from '../../assets/loading.png'

const Loading = () => {

    return (
        <Container>
            <Spinner>
                <Icon src = {LoadingIcon} alt = "Loading..."/>
            </Spinner>
        </Container>
    );
}

export default Loading;