import React from "react";
import { CardDefault } from "./style";

const Card = (props) => {

    return(
        <CardDefault>
            {props.children}
        </CardDefault>
    );
}

export default Card;