import React, { useState } from "react";
import {Header, PreAnswerArea, PreAnswerGroup} from '../style'
import Card from "../Card";

const MultipleResponse = ({question, updateAnswer}) => {

    const [checkboxes, setCheckboxes] = useState(
        new Array(question.preAnswers.length).fill(false)
    );

    const handleOnChange = (position) => {
        const updatedCheckboxes = checkboxes.map((item, index) => {
            return index === position ? !item : item;
        });
        setCheckboxes(updatedCheckboxes);

        let selectedPreAnswers = [];
        
        //don't have ordering
        if(question.preAnswerOrders.length == 0){
            selectedPreAnswers = question.preAnswers.filter((item, index) => {
                return (updatedCheckboxes[index] === true);
            });
        }else { //have ordering
            for(let i = 0 ; i < question.preAnswerOrders.length; i++){
                if(updatedCheckboxes[i] === true){
                    let preAnswer = question.preAnswers.find(preAnswer => {
                        return preAnswer.id === question.preAnswerOrders[i].pre_answer_id;
                    })
                    selectedPreAnswers.push(preAnswer);
                }
                
            }
        }

        const filteredAnswers = selectedPreAnswers.map((preAnswer) => {
            return preAnswer.id;
        });

        let answer = {
            id: question.id,
            type: question.type,
            answer: filteredAnswers
        }

        updateAnswer(question, answer);
        
    }

    return (
        <Card>
            <Header>
                {question.text}
            </Header>

            <PreAnswerArea>
                {
                    question.preAnswerOrders.length == 0 &&
                    question.preAnswers.map((preAnswer, index) => {
                        return(
                            <PreAnswerGroup key = {preAnswer.id}>
                                <input 
                                    type="checkbox" 
                                    name="preAnswer" 
                                    value={preAnswer.id}
                                    checked={checkboxes[index]}
                                    onChange={() => handleOnChange(index)}
                                />
                                <p>{preAnswer.text}</p>
                            </PreAnswerGroup>
                        );
                    })
                }
                {
                     question.preAnswerOrders.length > 0 && 
                     question.preAnswerOrders.map((preAnswerOrder, index) => {
                         let preAnswer = question.preAnswers.find(preAnswer => {
                             return preAnswer.id === preAnswerOrder.pre_answer_id;
                         })
                         
                         return(
                             <PreAnswerGroup key = {preAnswer.id}>
                                 <input 
                                    type="checkbox" 
                                    name="preAnswer" 
                                    value={preAnswer.id}
                                    checked={checkboxes[index]}
                                    onChange={() => handleOnChange(index)}
                                />
                                 <p>{preAnswer.text}</p>
                             </PreAnswerGroup>
                         );
                         
                     })
                }
            </PreAnswerArea>
        </Card>
    );
}

export default MultipleResponse;