import React from "react";
import { CloseWrapper, Container, Content, Page } from "./style";
import Icon from '../Icon';
import CrossIcon from '../../assets/K2.svg';
import { useRef } from "react";
import { useClickedOutside } from "../../hooks/useClickedOutside";
import ReactDOM from 'react-dom';

const Modal = ({isOpen, handleClose, children }) => {

    const modalRef = useRef(null);
    const modal = document.getElementById('modal');

    useClickedOutside(modalRef, handleClose, isOpen);

    const initModal = () => {
        return (
        <Container>
            <Page >
                <Icon classes = "cursor-click" onclick={handleClose} src = {CrossIcon} alt = "close icon"/>
                <Content ref = {modalRef}>
                    {children}
                </Content>
            </Page>
        </Container>
    );

    }

    if(!isOpen) {
        return (<></>);
    }
    /*
    return (
        <Container>
            <Page >
                <Icon classes = "cursor-click" onclick={handleClose} src = {CrossIcon} alt = "close icon"/>
                <Content ref = {modalRef}>
                    {children}
                </Content>
            </Page>
        </Container>
    );
    */
    return (
        ReactDOM.createPortal(initModal(), modal)
    );

};

export default Modal;