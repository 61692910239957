import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextField from '../TextField';


const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    required: PropTypes.bool,
};

const defaultProps = {
    required: false,
    onChange: () => {}
};

class EmailField extends Component {

    state = {
        value: this.props.value,
        isValid: false,
        feedbackText: 'E-mail inválido',
        waiting: false,
        emailRepeat: false,
    };

    isValid = () => {
        const regexEmail =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexEmail.test(this.state.value);
    };

    handleOnChange = (e, isValid) => {
        e.persist();

        this.setState({
            value: e.target.value,
        }, () => {
            this.setState({
                isValid: this.isValid(e.target.value)
            }, () => {
                this.props.onChange(e, this.state.isValid);
            });
        });


    };

    handleOnBlur = (e) => {
        e.persist();

        if (! this.state.isValid) {
            return;
        }

        const value = e.target.value;

        const handleError = (response) => {
            if (! response.ok) {
                throw Error('An error occur:', response.statusText);
            }
            return response;
        };

        const setStateByResponse = (isValid, feedbackText, emailRepeat = false) => {
            this.setState({
                isValid: isValid,
                feedbackText: feedbackText,
                waiting: false,
                emailRepeat: emailRepeat
            }, () => {
                this.props.onChange(e, this.state.isValid);
            });
        };

        // 'http://httpstat.us/404'
        this.setState({waiting: true}, () => {
            fetch(`${process.env.REACT_APP_API_REGISTER}/subscribers/check-if-email-exists`, {
                method: 'post',
                body: JSON.stringify({email: value}),
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(handleError)
            .then(response => response.json())
            .then(json => {
                if (json.success === true && json.data.exists === true) {
                    setStateByResponse(false, 'E-mail já cadastrado.', true);
                } else {
                    setStateByResponse(true, '');
                }
            })
            .catch(error => {
                setStateByResponse(false, 'Erro interno do servidor. Por favor, tente novamente mais tarde.')
            });
        });

    };

    render() {
        return (
            <>
                <TextField
                    type="email"
                    label={this.props.label}
                    name={this.props.name}
                    required={this.props.required}
                    value={this.props.value}
                    feedbackText={this.state.feedbackText}
                    isValid={this.state.isValid}
                    onChange={e => this.handleOnChange(e)}
                    onBlur={e => this.handleOnBlur(e)}
                    waiting={this.state.waiting}
                />
                {(this.state.emailRepeat) &&
                    <span style={{color: 'red'}}>Talvez você ja tenha uma conta, <a target="_blank" href='https://account.ooppah.com'>clique aqui</a> para gerencia-la</span>
                }
            </>
        );
    }
}

EmailField.propTypes = propTypes;
EmailField.defaultProps = defaultProps;

export default EmailField;
