import styled from 'styled-components';
import goldButton from '../../assets/gold_buton.png'


export const Container = styled.div`
    display: flex;
    padding: 60px 0 0 0;
    flex-direction: column;
    background-color:#f9f6f4;

`;

export const Top = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

export const Plans = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    margin-bottom: 20px;
    margin: auto;
    
    @media (min-width: 1080px){
        margin-bottom: 0;
        flex-direction: row;
        width: 100%;
    }

`;

export const Plan = styled.div`
    position:relative;
    border: 2px solid #131F30;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 5px;
    width: 100%;
    padding-top: 15px;
    @media (min-width: 1080px){
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .space {
        margin-bottom:15px;
    }
`;

export const Content = styled.div`
    border-style: border-box;
    padding: 20px;

`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

`;

export const Text = styled.p`
    width: 100%;
    color: ${props => props.color? props.color : "#131F30"};
    text-align: center;
    font-size: ${props => props.size};
    padding-top: 3px;
`;

export const PremiumButton = styled.button`
    background: none;
    background-image: url(${goldButton});
    background-repeat: no-repeat;
    background-size: 100%;
    border: none;
    margin: 15px 0;
    cursor: pointer;
    width: 95%;
    padding: 14px 20px;
    color: white;
`;

export const FreeButton = styled.button`
    background: none;
    border: none;
    background-color: #131F30;
    border-radius: 10px;
    margin: 23px 0;
    cursor: pointer;
    width: 95%;
    padding: 10px 20px;
    color: white;
`;

export const CompetitorButton = styled.button`
    background: none;
    border: none;
    background-color: #4d4d4d;
    border-radius: 10px;
    margin: 23px 0;
    cursor: pointer;
    width: 95%;
    padding: 10px 20px;
    color: white;
    
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-left: 10px;
`;

export const AttentionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

`;

export const CrossContainer = styled.div`
    display: inline-block;
    position: relative;
`;

export const CrossIcon = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    
`;

export const PercentIcon = styled.img`
    position: absolute;
    top: -15px;
    width: 100%;
`;

export const BlackWeekIcon = styled.img`
    position: absolute;
    width: 80px;
    height: 60px;
    top: 0;
    left: 10px;
    @media (min-width: 1080px){
        width: 100px;
        height: 80px;
        top: -20px;
        left: 10px;
    }
`;

export const Esgotado = styled.div`
    position: absolute;
    background-color: black;
    color: white;
    font-weight: bold;
    font-size: 30px;
    padding: 10px 100px;
    
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(30deg);
    z-index: 2;

`;

export const EsgotadoText = styled.div`
    position: absolute;
    background-color: black;
    color: white;
    text-align: center;
    font-size: 20px;
    border-radius: 10px;
    padding: 10px;
    top: 80%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;

`;

export const Curtain = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #ccc;
    opacity: 0.6;
`;