import React from 'react';

import {Container} from './style';

const MenuBackground = (props) => {

    return (
        <Container>
            {props.children}
        </Container>
    );
}

export default MenuBackground;