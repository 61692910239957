import styled from 'styled-components';
import logoPlay from './../../assets/logoStore.png'
import logoPlayWhite from './../../assets/logoStore_white.png'

export const Container = styled.div`
    display: block;
    margin-left: 14px;
    @media(min-width: 1080px) {
        margin-bottom: 0;
        margin-left:0px;
        margin-right: 20px;
    }
`;

export const LogoImage = styled.img`
    width: 60px;
`;