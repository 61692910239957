import React from 'react';
import {Container} from './style';

const Icon = (
    { src, width = "1.1em", height = '1.1em',
     alt, classes = '', show = true, 
     backgroundColor = '', onclick,
     paddingLeft = 0}) => {

    if(!show){
        return (<></>) ;
    }

    return(
        <Container onClick = {onclick}  className={classes} backgroundColor = {backgroundColor} style = {{paddingLeft: paddingLeft, cursor: onclick ? 'pointer': ''}}>
            <img src= {src} alt= {alt} style={{width: width, height: height}} />
        </Container>
    );
}

export default Icon;