import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from "../TextField/index";

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    isValid: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    country: PropTypes.string,
    mask: PropTypes.string,
    maxLength: PropTypes.number,
    feedbackText: PropTypes.string,
    waiting: PropTypes.bool,
    tabIndex: PropTypes.number
};

const defaultProps = {
    required: false,
    isValid: true,
    country: 'br',
    name: 'zipcode',
    label: 'Zipcode',
    mask: '11111111',
    maxLength: 8,
    feedbackText: 'CEP inválido',
    onChange: () => {},
    onBlur: () => {},
    waiting: false,
    tabIndex: 0
};

class ZipCodeField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: this.props.isValid
        };
    }

    /**
     * Handler onChange.
     *
     * @param e
     * @param isValid
     */
    handlerOnChange = (e, isValid) => {
        e.persist();

        const length = e.target.value.replace(/[^0-9]/g, '').length;
        let valid = true;

        if (length < this.props.maxLength) {
            valid = false
        }

        this.setState({
            isValid: valid
        }, () => this.props.onChange(e, this.state.isValid));
    };

    /**
     * Handler onBlur.
     *
     * @param e
     * @param isValid
     */
    handleOnBlur = (e, isValid) => {
        const length = e.target.value.replace(/[^0-9]/g, '').length;
        
        if (length === this.props.maxLength) {
            this.props.onBlur(e, isValid);
        }
    };

    render() {
        return (
            <TextField
                type="tel"
                label={this.props.label}
                name={this.props.name}
                isValid={this.state.isValid}
                required={this.props.required}
                onChange={this.handlerOnChange}
                onBlur={this.handleOnBlur}
                mask={this.props.mask}
                maxLength={this.props.maxLength}
                feedbackText={this.props.feedbackText}
                waiting={this.props.waiting}
                tabIndex={this.props.tabIndex}
            />
        );
    }
}

ZipCodeField.propTypes = propTypes;
ZipCodeField.defaultProps = defaultProps;

export default ZipCodeField;
