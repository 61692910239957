import React, { Component } from 'react'
import Swiper from 'swiper/dist/js/swiper.min.js';
import 'swiper/dist/css/swiper.min.css';
import axios from 'axios';

// Style
import './style.css'

// Images e Assets
import artista from '../../assets/artista.png'
import artistaPrev from '../../assets/artista-prev.png'
import artistaNext from '../../assets/artista-next.png'
import aspas from '../../assets/aspas.png'

export default class Depoimentos extends Component {

    state = {
        slideItems: []
    }

    componentDidMount() {

        axios.get(`https://ooppah.com.br/api/testimonials`)
			.then(res => {
			    let dataSlide = res.data.data;
                let arr = [];
				dataSlide.map((elem, index) => {
                    arr.push(
                        <div key={index} className="swiper-slide">
                            <div className="slide-cont">
                                <div className="quadro-depoimento">
                                    <img src={aspas} />
                                    <div className="quadro-depoimentos-texto">
                                        {elem.testimonial}
                                    </div>
                                    <div className="quadro-depoimento-nome">
                                        {elem.name}
                                        <span>{elem.role}</span>
                                    </div>
                                </div>
                                <div className="depoimento-avatar">
                                    <img src={elem.avatar} />
                                </div>
                            </div>
                        </div>   
                    )
                })
                this.setState({slideItems: arr})
                const depoimentosSwiper = new Swiper('.depoimentos-slide', {
                    slidesPerView: 4,
                    spaceBetween: 20,
                    navigation: {
                        nextEl: '.depoimentos-next',
                        prevEl: '.depoimentos-prev'
                    },
                    breakpoints:{
                        1080:{
                            slidesPerView: 1,
                        }
                    }
                });
			}
        )


		
    }
    
    render() {
        return (
            <div id="depoimentos" className="depoimentos-section">
                <div className="depoimentos-topo">
                    <h2>Depoimentos</h2>
                    <p>Veja o que grandes profissionais do meio artístico estão falando sobre o Ooppah.</p>
                </div>
                <div className="container">
                    <div className="depoimentos-slide swiper-container">
                        <div className="swiper-wrapper">
                            {this.state.slideItems}
                                          
                        </div>
                    </div>
                    <div className="depoimentos-slide-nav">
                        <div className="depoimentos-prev"><img src={artistaPrev} /></div>
                        <div className="depoimentos-next"><img src={artistaNext} /></div>
                    </div>
                </div>
            </div>
        )
    }
}
    