import styled from 'styled-components';

export const Footer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    @media (min-width: 1080px){
        flex-wrap: nowrap;
    }
        
`;

export const Etapa = styled.div`
    display: flex;
    margin-bottom: 10px;
    .numero {
        align-items: center;
        background-color: #f9f6f4;
        border-radius: 50%;
        border: 1px solid #cdcbca;
        color: #cdcbca;
        cursor: pointer;
        display: flex;
        font-size: 12px;
        height: 25px;
        justify-content: center;
        margin: 5px;
        overflow: hidden;
        width: 25px;

        &.ativo, &:hover {
            border: 1px solid #3b599a;
            color: #3b599a;
        }
        
    }
`

export const SelectedPlan = styled.div`
    margin-bottom: 10px;
    .topo-titulo {
        font-size: 10px;
        text-transform: uppercase;
    }
    .destaque {
        color: #3b599a;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }
`;

export const Button = styled.div`
    cursor: pointer;
    background-color: #3b599a;
    border-radius: 40px;
    border: 1px solid #3b599a;
    font-size: 17px;
    padding: 25px 55px;
    text-transform: uppercase;
    color: #FFF;
    text-align: center;
    width: 100%;

    @media (min-width: 1080px){
        width: auto;
    }
        
`;