import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Menu from '../../components/menu';
import FloatActionButton from "../../components/FloatActionButton";
import MenuBackground from "../../components/MenuBackground";
import RegisterForm from "../../components/RegisterForm";
import Rodape from "../../containers/Rodape";
import LogoWp from '../../assets/LogoWP.png';

const Registration = () => {
    let { planCode } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div>
            <MenuBackground>
                <Menu isFaq={true} isStatic= {true}/>
            </MenuBackground>
            <RegisterForm planCode={planCode}/>
            <Rodape />
            <FloatActionButton linkTo = "https://wa.me/5521994765872">
                <img src = {LogoWp} />
            </FloatActionButton>
        </div>


    );


};

export default Registration;