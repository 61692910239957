import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PlanPlaceholder from "../placeholders/PlanPlaceholder/index";
import CouponForm from "../CouponForm/index";
import fetchQL from '../../support/fetch';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    minor: PropTypes.bool.isRequired
};

class Plans extends Component {

    state = {
        plans: [],
        plansData: {},
        showPlaceholders: true,
        couponInvalid: false,
        couponWaiting: false,
        couponShowSuccessMessage: false,
        couponCode: null,
        fetchingCouponInfo: false,
        couponInfo: {}
    };

    setPlan = i => {
        this.props.onChange(this.state.plansData[i]);
    };

    loadPlans = (minor, couponCode = false) => {

        let apiUrl = `${process.env.REACT_APP_API_REGISTER}/plans?subscriber_type=${minor ? 'minor' : 'adult'}&planCode=${this.props.planCode}`;

        if (couponCode) {
            apiUrl += `&coupon=${couponCode}`
        }

        this.setState({
            plans: []
        }, () => {
            fetch(apiUrl)
                .then(response => response.json())
                .then(json => {

                    this.setState({
                        plansData: json.data,
                        couponWaiting: false
                    });

                    const plans = json.data.map((plan, i) => {
                        return (
                            <label key={i} htmlFor="mensal" className="bloco-plano selecionado" onClick={() => this.setPlan(i)}>
                                <div className="bloco-plano-cont">
                                    <div className="bloco-plano-topo">
                                        <div className="tipo">{plan.name.toUpperCase()}</div>
                                        <div className="pagamento">
                                            {plan.payment_method === 'C' ? 'Cartão de Crédito' : 'Boleto'}
                                        </div>
                                    </div>
                                    <div className="bloco-plano-preco">
                                        <p>de {plan.currency} {plan.amount_from} por</p>
                                        <div className="destaque">{plan.currency} {plan.amount}</div>
                                    </div>
                                    <div className="bloco-plano-descricao">
                                        <p>{plan.description}</p>
                                    </div>
                                    <button className="bloco-btn-cadastrar" type="button">Cadastrar</button>
                                </div>
                            </label>
                        );
                    });

                    this.setState({
                        plans: plans
                    }, () => {
                        if (couponCode !== false) {
                            if (this.state.plans.length === 0) {
                                // Cupom inválido.
                                // Chama novamente a lista de planos, sem informar o código
                                // do cupom para a requisição.
                                this.loadPlans(this.state.minor);
                                this.setState({couponInvalid: true, couponShowSuccessMessage: false});
                            } else {
                                this.setState({couponInvalid: false, couponShowSuccessMessage: true});

                            }
                        }

                    });
                });
        });
    };

    componentDidMount = () => {
        this.loadPlans(this.props.minor);
    };

    componentWillUpdate = (prevProps, prevState) => {
        if (this.state.couponCode !== null && this.state.fetchingCouponInfo === false) {
            // console.log('Coupon code: ', this.state.couponCode);
            this.setState({fetchingCouponInfo: true}, () => {
                fetchQL.obj({
                    query: `query GetCouponInfo($code: String!) {
                    findCouponByCode(code: $code) {
                        code,
                        description
                    }
                }`,
                    variables: {
                        code: this.state.couponCode
                    }
                })
                .then(res => {
                    this.setState({couponInfo: res.data.findCouponByCode});
                })
                .then(this.setState({fetchingCouponInfo: true}))
            });

        }
       // this.willLoadCouponInfo(prevState);

    };

    receiveCouponCode = (couponCode) => {

        if (couponCode.trim() === '') {
            this.setState({couponInvalid: true});
        } else {
            this.loadPlans(this.state.minor, couponCode);
            this.setState({couponWaiting: true});
        }

        this.setState({couponCode: couponCode});
    };


    render = () => {
        return (
            <div>
                <CouponForm
                    onValidate={this.receiveCouponCode}
                    waiting={this.state.couponWaiting}
                    invalid={this.state.couponInvalid}
                    feedbackText="Cupom inválido"
                    couponShowSuccessMessage={this.state.couponShowSuccessMessage}
                />

                {
                    !this.state.couponInvalid && this.state.couponInfo.hasOwnProperty('code') &&
                    <div
                        style={{
                            width: '50%',
                            textAlign: 'center',
                            margin: '0 auto 30px auto',
                            color: 'green'
                        }}>
                        <h1 style={{fontWeight: 700}}>Parabéns, seu cupom é válido!</h1>
                        <strong>
                            {this.state.couponInfo.description}
                        </strong>
                    </div>
                }

                {
                   this.state.plans.length === 0 && this.state.showPlaceholders
                    ?
                        <div className="lista-planos">
                            <PlanPlaceholder />
                            <PlanPlaceholder />
                            <PlanPlaceholder />
                            <PlanPlaceholder />
                            <PlanPlaceholder />
                            <PlanPlaceholder />
                        </div>
                    :
                       <div className="lista-planos">
                           {this.state.plans}
                       </div>
                }
            </div>
        );
    };
}

export default Plans;
