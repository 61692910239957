import React from 'react';
import {BrowserRouter as Router, Route, Switch}from 'react-router-dom'

import Landing from '../pages/Landing/App';
import FAQ from '../pages/FAQ';
import Redirect from '../components/Redirect';
import Club from '../pages/Club';
import Survey from '../pages/Survey';
import Plans from '../pages/Plans';
import FreeRegistration from '../pages/FreeRegistration';
import Registration from '../pages/Registration';

const Main = () => {
    return (
        <Router>
            <Switch>
                <Route exact={true} path="/" component={Landing} />
                <Route exact={true} path="/faq" component={FAQ} />
                <Route exact={true} path="/clube" component={Club}/>
                <Route exact={true} path="/survey/:id" component={Survey} />
                <Route exact={true} path="/plans" component={Plans} />
                <Route exact={true} path="/freeregistration" component={FreeRegistration} />
                <Route exact={true} path="/registration/:planCode" component={Registration} />
                <Route component = {Redirect}/>
            </Switch>
        </Router>
    );
}

export default Main;
