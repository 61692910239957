import styled from 'styled-components';

export const Content = styled.div`
    background-color: #FFFFFF;
    display: block;
    
    width: 100%;
    @media (min-width: 1080px) {
        
    }
    .centralized {
        display:flex;
        justify-content:center;
        align-items:center;
    }
`;