import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Menu from "../../components/menu";
import MenuBackground from "../../components/MenuBackground";
import {MainTextWrapper, Container, Image, PDFLinkLeft, PDFLinkRight, Title, TextArea, Paragraph, SubTitle, UnorderedList, ListItem, BannerImage, ImageArea } from "./style";

// import BannerCNA from "./../../assets/banner_CNA.png";
// import BannerCrizzyHair from './../../assets/banner_crizzyhair.png';
// import BannerMyLash from "./../../assets/banner_mylash.png";
// import BannerTiageMusical from "./../../assets/banner_tiagemusical.png";
// import BannerTopEstetica from './../../assets/banner_topestetica.png';
// import BannerCentroMusical from './../../assets/banner_centromusical.png';
// import BannerDentista from './../../assets/banner_dentista.png'

// import PDFCNA from "./../../assets/pdfs/Condicoes_CNA_Botafogo.pdf";
// import PDFCrizzi from "./../../assets/pdfs/Condicoes_Crizzi.pdf";
// import PDFMyLash from "./../../assets/pdfs/condicoes_My_Lash.pdf";
// import PDFTiaGeMusical from "./../../assets/pdfs/Condicoes_Tia_Ge_Musical.pdf";
// import PDFTopEstetica from "./../../assets/pdfs/Condicoes_Mais_Top_Estetica_Ipanema.pdf";
// import PDFDentista from './../../assets/pdfs/PDFLeoDentista.pdf';

import BannerMyLashV2 from '../../assets/Banner_My_Lash.jpg'
import BannerAntonioAdolfo from '../../assets/Banner_Antonio_Adolfo.png'
import BannerTiaGe from '../../assets/Banner_Tia_Ge.jpg'
import BannerFaculdadeCesgranrio from '../../assets/Banner_Ooppah_Clube_Cesgranrio_2.png'

import PDFMyLashV2 from '../../assets/pdfs/Ooppah_Clube_My_Lash.pdf'
import PDFAntonioAdolfo from '../../assets/pdfs/Ooppah_Clube_Antonio_Adolfo.pdf'
import PDFTiaGe from '../../assets/pdfs/Ooppah_Clube_Tia_Ge.pdf'
import PDfFaculdadeCesgranrio from '../../assets/pdfs/Ooppah_Clube_Faculdade_Cesgranrio.pdf'

import Rodape from "../../containers/Rodape";
import FloatActionButton from "../../components/FloatActionButton";
import LogoWp from '../../assets/LogoWP.png';

const Club = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Helmet>
                <title>Ooppah! Clube de Parceiros - Benefícios</title>
                <meta name='description' content="Olha os Benefícios que o Ooppah pode te trazer!"/>
                <meta property="og:title" content="Ooppah! Clube de Parceiros - Benefícios"/>
                <meta property="og:description" content="Olha os Benefícios que o Ooppah pode te trazer!" />
            </Helmet>

            <MenuBackground>
                <Menu isFaq={true} isStatic= {true}/>
            </MenuBackground>
            <MainTextWrapper>
                <Title id = "top">Ooppah Clube</Title>
                <TextArea>
                    <Paragraph>
                        A área de benefícios foi criada exclusivamente para os <b>assinante premium</b> com o 
                        objetivo de oferecer aos atores e atrizes a oportunidade de <b>construírem 
                        sua melhor versão.</b> 
                    </Paragraph>
                    <Paragraph>
                        Pensando nas necessidades desse público, buscamos oferecer os <b>melhores benefícios</b> aderentes ao mundo dos atores, 
                        para que a nossa missão se torne realidade.
                        Desta forma queremos ao nosso lado os <b>melhores parceiros</b>, para juntos, contribuirmos 
                        com o <b>crescimento profissional do artista</b>, abrindo portas para novas <b>possibilidades 
                        e oportunidades</b>.
                    </Paragraph>
                </TextArea>
            </MainTextWrapper>
            
            <MainTextWrapper>
                <SubTitle>
                    Como utilizar seu Benefício
                </SubTitle>

                <TextArea>
                    <UnorderedList>
                        <ListItem>Apresente-se ao local do parceiro.</ListItem>
                        <ListItem>
                            No seu celular, mostre seu perfil online na plataforma 
                            Ooppah para a validação do desconto (<b>prints de tela não são aceitos</b>).
                        </ListItem>
                        <ListItem>
                            Pronto! Você já pode usufruir do seu benefício!
                        </ListItem>
                    </UnorderedList>
                </TextArea>
            </MainTextWrapper>
            <ImageArea>
                 <BannerImage>
                    <a href={PDFMyLashV2} target="_blank">
                        <Image src={BannerMyLashV2} alt="Promoção My Lash com descontoem produtos da franquia." />
                    </a>
                </BannerImage>

                 <BannerImage>
                    <a href={PDfFaculdadeCesgranrio} target="_blank">
                        <Image src={BannerFaculdadeCesgranrio} alt="Promoção desconto na mensalidade da faculdade Cesgranrio." />
                    </a>
                </BannerImage>

                 <BannerImage>
                    <a href={PDFTiaGe} target="_blank">
                        <Image src={BannerTiaGe} alt="Promoção curso Tia Gê Musical com desconto." />
                    </a>
                </BannerImage>

                 <BannerImage>
                    <a href={PDFAntonioAdolfo} target="_blank">
                        <Image src={BannerAntonioAdolfo} alt="Promoção centro musical Antonio Adolfo." />
                    </a>
                </BannerImage>
            </ImageArea>
            <Rodape />
            <FloatActionButton linkTo = "https://api.whatsapp.com/send?1=pt_BR&phone=+5521993330503">
				<img src = {LogoWp} alt="Logo Whatsapp" rel="noopener" />
			</FloatActionButton>
        </Container>
    );
}

export default Club;