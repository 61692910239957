import React, { Component } from 'react';
import Swiper from 'swiper/dist/js/swiper.min.js';
import 'swiper/dist/css/swiper.min.css';
import axios from 'axios';

// Style
import './style.css'

// Images e Assets
import artista from '../../assets/artista.png'
import artistaPrev from '../../assets/artista-prev.png'
import artistaNext from '../../assets/artista-next.png'
import aspas from '../../assets/aspas.png'

export default class Quemsomos extends Component {
    
    state = {
        slideItems: []
    }

    componentDidMount() {

        axios.get(`https://ooppah.com.br/api/whoweare`)
			.then(res => {
			    let dataSlide = res.data.data;
                let arr = [];
				dataSlide.map((elem, index) => {
                    arr.push(
                        <div key={index} className="swiper-slide">
                            <div className="slide-cont">
                                <div className="quadro-quemsomos">
                                    <img src={aspas} />
                                    <div className="quadro-quemsomos-texto">
                                        {elem.message}
                                    </div>
                                    <div className="quadro-quemsomos-nome">
                                        {elem.name}
                                        <span>{elem.role}</span>
                                    </div>
                                </div>
                                <div className="quemsomos-avatar">
                                    <img src={elem.avatar} />
                                </div>
                            </div>
                        </div>   
                    )
                })
                this.setState({slideItems: arr})
                const depoimentosSwiper = new Swiper('.quemsomos-slide', {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    navigation: {
                        nextEl: '.quemsomos-next',
                        prevEl: '.quemsomos-prev'
                    },
                    breakpoints:{
                        1080:{
                            slidesPerView: 1,
                        }
                    }
                });
			}
        )
    }

    render() {
        return (
            <div id="quemsomos" className="quemsomos-section">
                <div className="quemsomos-topo">
                    <h2>Quem somos</h2>
                    <p>Olha só a equipe que está por trás do Ooppah!</p>
                </div>
                <div className="container">
                    <div className="quemsomos-slide swiper-container">
                        <div className="swiper-wrapper">
                            {this.state.slideItems}
                                          
                        </div>
                    </div>
                    <div className="quemsomos-slide-nav">
                        <div className="quemsomos-prev"><img src={artistaPrev} /></div>
                        <div className="quemsomos-next"><img src={artistaNext} /></div>
                    </div>
                </div>
            </div>
        )
    }
}