import React, {Component} from 'react';

// Style
import {BlackFridayBanner, Container, Description, DescriptionText, DescriptionTitle, Video, OoppahName} from './styles';
import Menu from '../../components/menu';

// Assets
import video from '../../assets/videos/apresentacao-artistas.mp4';
import BlackFridayImage from '../../assets/blackfridaybanner.jpeg';

export default class Topo extends Component {
    state = {
        showVideo: false,
        muted: true
    };

    componentDidMount = () => {
        const __this = this;
        window.onload = function() {
            __this.setState({showVideo: true});
        }
    };

    render() {
        return (
            <Container id="topBanner">

                <Video src={video} className='video' />
                
                <Menu isStatic = {false}/>
                
                <Description>
                    <DescriptionTitle>Seja visto.<br/> Seja <OoppahName>OOPPAH</OoppahName>!</DescriptionTitle>
                    <DescriptionText>
                        Somos uma ferramenta completa que facilita a interação entre ARTISTAS,
                        PRODUTORES DE ELENCO, PESQUISADORES e AGENTES, gerando mais oportunidades de trabalho.
                    </DescriptionText>                
                </Description>
            </Container>
        );
    }
}
