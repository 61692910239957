import React, {Component, useState} from 'react';
import PropTypes from 'prop-types';
import TextField from "../TextField/index";
import * as moment from 'moment';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    feedbackText: PropTypes.string,
    mask: PropTypes.string,
    dateFormat: PropTypes.string,
    required: PropTypes.bool
};

const defaultProps = {
    mask: '11/11/1111',
    dateFormat: 'DD/MM/YYYY',
    feedbackText: 'Data inválida',
    required: false,
    onChange: () => {}
};


class DateFieldFree extends Component {

    state = {
        isValid: false,
        isMajor: false,
    };

    checkBirthday = (birthdate) => {

        if(birthdate.length === 10){
            let [day, month, year] = birthdate.split('/');
            let newbirthdate =  year + "-" + month + "-" + day;
            let ageDifMs = Date.now() - new Date(newbirthdate).getTime();
            let ageDate = new Date(ageDifMs);
            return  Math.abs(ageDate.getUTCFullYear() - 1970) > 17;
        }

        return false;

    }

    /**
     * Handle onChange.
     *
     * @param e
     * @param isValid
     */
    handleOnChange = (e, isValid) => {
        e.persist();
        const inputValueLength = e.target.value.replace(/[\/_]/g,'').length; // accepted chars length.
        const dateIsValid = moment(e.target.value, this.props.dateFormat).isValid();
        let isMajor = true;
        if(e.target.value.length === 10){
            isMajor = this.checkBirthday(e.target.value);
            this.setState({
                isMajor: isMajor
            });
        }

        this.setState({
            isValid: (inputValueLength === 8) && dateIsValid && isMajor
        }, () => {
            this.props.onChange(e, this.state.isValid);
        });
    };

    
    render() {
        return (
            <TextField
                type="tel" // hacking to show a numeric keyboard to the user.
                label={this.props.label}
                name={this.props.name}
                mask={this.props.mask}
                feedbackText={this.state.isMajor ? this.props.feedbackText: "O usuário deve possuir ao menos 18 anos!"}
                isValid={this.state.isValid}
                onChange={this.handleOnChange}
                required={this.props.required}
            />
        );
    }
}

DateFieldFree.propTypes = propTypes;
DateFieldFree.defaultProps = defaultProps;

export default DateFieldFree;