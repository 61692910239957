import React, {useState, useEffect} from 'react';
import axios from 'axios';

import FAQPost from '../../components/FAQPost';
import FAQSearch from '../../components/FAQSearch';
import Pagination from '../../components/Pagination';

import {Container} from './style';
import Loading from '../../components/Loading';
import { useLocation, useHistory    } from "react-router-dom";

const FAQBody = () => {

    const [posts, setPosts] = useState({
        data: [],
        pagination: {
            total: 0,
            count: 0,
            per_page: 15,
            current_page: 1,
            total_pages:1,
            links: {},
        },
    });

    const [currentPage, setCurrentPage] = useState(1);

    const [keyword, setKeyword] = useState("");
    const [urlKeyword, setUrlKeyword] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    //Deve ser usada no escopo do componente e não dentro de uma função
    let queryUrl = GetQuery();
    let myHistory = GetHistory();

    const handleKeywordChange = (event) => {
        setKeyword(event.target.value);
    }

    const handleSearch = () => {

        myHistory.push({
            pathname: '/faq',
            search: `?q=${keyword}`
        })
        window.location.reload();
    }

    const handlePageChange = (pageNumber) =>{
        if(typeof pageNumber !== 'number'){return}
        setCurrentPage(pageNumber);
    }

    const handlePageNext = () => {
        if(currentPage >= posts.pagination.total_pages) {return}

        let newCurrentPage = currentPage + 1;
        setCurrentPage(newCurrentPage);
    }

    const handlePagePrev = () => {
        if(currentPage <= 1) {return}
        
        let newCurrentPage = currentPage - 1;
        setCurrentPage(newCurrentPage);
    }

    const loadPosts = async () => {
        //setar loading pra verdadeiro
        setIsLoading(true);
        
        let tempKeyword = queryUrl.get("q") ? queryUrl.get("q") : keyword;

        let url = tempKeyword ? `${process.env.REACT_APP_API_REGISTER}/faq?page=${currentPage}&keyword=${tempKeyword}`: `${process.env.REACT_APP_API_REGISTER}/faq?page=${currentPage}`

        axios.get(url)
            .then((res) => { //success
                setPosts({data: res.data.data, pagination: {...res.data.meta.pagination}});
                //Setar loading pra falso;
                setIsLoading(false);
            })
            .catch(() => { //error
                console.log("Falhou");
                setIsLoading(false);
            }
        );

    }

    function GetQuery() {
        return new URLSearchParams(useLocation().search);
    }

    function GetHistory(props) {
        return useHistory();
    }

    useEffect(()=> {
        loadPosts();
    }, [currentPage]);
    
    return (

        <Container>
             
            <React.Fragment>
                <FAQSearch
                    keyword = {keyword}
                    handleKeywordChange = {handleKeywordChange}
                    handleSearch = {handleSearch}
                />
                {
                    isLoading && <Loading /> 
                }
                {
                    !isLoading 
                    &&
                    <React.Fragment>    
                        {posts.data.map((post, index) => {
                            return (
                                <FAQPost key={index} post = {post}/>
                            )
                        })}

                        <Pagination 
                            pagination = {posts.pagination}
                            handlePageChange = {handlePageChange}
                            handlePageNext = {handlePageNext}
                            handlePagePrev = {handlePagePrev}
                        />
                    </React.Fragment>
                }
            </React.Fragment>
        
        </Container>
    );
}

export default FAQBody;