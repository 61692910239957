import styled from 'styled-components';


//justify-content organiza os itens de forma horizontal
//align-item organiza os itens de forma vertifical
export const Title = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 5px;

    font-size: 25px;
    color: #e78724;
    font-weight: bold;
`;