import React, {useEffect} from 'react';



const Redirect = () => {

    
    useEffect(() => {
        let pathname = window.location.pathname;
        window.location.href = "https://profile.ooppah.com" + pathname;
    }, []);
    
    return (
        <div>

        </div>
    );
}

export default Redirect;