import React, { Component } from 'react'
import Swiper from 'swiper/dist/js/swiper.min.js';
import 'swiper/dist/css/swiper.min.css';

// Style
import './style.css'

// Images e Assets
import iconPerfil from '../../assets/iconPerfil.png';
import iconFotos from '../../assets/iconFotos.png';
import iconVideos from '../../assets/iconVideos.png';
import iconEstatisticas from '../../assets/iconEstatisticas.png';
import iconMensagens from '../../assets/iconMensagens.png';

import imgPerfil from '../../assets/plataforma/ooppah_ilustracao_perfil.png';
import imgFotos from '../../assets/plataforma/ooppah_ilustracao_fotos.png';
import imgVideo from '../../assets/plataforma/ooppah_ilustracao_video.png';
import imgEstatisticas from '../../assets/plataforma/ooppah_ilustracao_estatisticas.png';
import imgMensagens from '../../assets/plataforma/ooppah_ilustracao_mensagens.png';

export default class Plataforma extends Component {

    componentDidMount() {
        const swiperThumb = new Swiper('.plataforma-thumb', {
            spaceBetween: 0,
            slidesPerView: 5,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            direction: 'vertical',
            breakpoints:{
                1080:{
                    slidesPerView: 'auto',
                    spaceBetween: 20,
                    direction: 'horizontal',
                }
            }
        });
		const swiper = new Swiper('.plataforma-slide', {
			slidesPerView: 1,
            spaceBetween: 20,
            autoplay:{
                delay: 5000
            },
            thumbs: {
                swiper: swiperThumb
            }
        });
    }
    
    render() {
        return (
            <div id="plataforma" className="plataforma-section">
                <div className="plataforma-topo">
                    <h2>Seja o dono do seu futuro</h2>
                    <p>Profissionalize a maneira de se apresentar para as oportunidades</p>
                </div>
                <div className="container">
                <div className="plataforma-thumb swiper-container">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="slide-icon">
                                    <div className="icon">
                                        <img src={iconPerfil} alt="Perfil"/>
                                    </div>
                                    <p>Perfil</p>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="slide-icon">
                                    <div className="icon">
                                        <img src={iconFotos} alt="Fotos"/>
                                    </div>
                                    <p>Fotos</p>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="slide-icon">
                                    <div className="icon">
                                        <img src={iconVideos} alt="Videos"/>
                                    </div>
                                    <p>Videos</p>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="slide-icon">
                                    <div className="icon">
                                        <img src={iconEstatisticas} alt="Endereço exclusivo"/>
                                    </div>
                                    <p>Endereço exclusivo</p>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="slide-icon">
                                    <div className="icon">
                                        <img src={iconMensagens} alt="Mensagens"/>
                                    </div>
                                    <p>Mensagens</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="plataforma-slide swiper-container">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="slide-cont">
                                    <div className="slide-texto">
                                        <h2>Perfil Personalizado</h2>
                                        <p>
                                            Você sempre em evidência! Personalize seu perfil com fotos, vídeos, áudios,
                                            características, experiências profissionais e muito mais.
                                        </p>
                                    </div>
                                    <div className="slide-image">
                                        <img src={imgPerfil} alt="Perfil" />
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="slide-cont">
                                    <div className="slide-texto">
                                        <h2>Fotos</h2>
                                        <p>
                                            Arraste, solte, organize por pastas como você quiser. Você escolhe o que
                                            quer cadastrar e destacar no seu perfil,
                                            para ser visto primeiro pelos visitantes da sua página.
                                        </p>
                                    </div>
                                    <div className="slide-image">
                                        <img src={imgFotos} alt="Fotos" />
                                    </div>
                                </div>
                            </div><div className="swiper-slide">
                                <div className="slide-cont">
                                    <div className="slide-texto">
                                        <h2>Vídeos</h2>
                                        <p>
                                            Seus vídeos do YouTube, Vimeo e Google Drive organizados num só lugar.
                                        </p>
                                    </div>
                                    <div className="slide-image">
                                        <img src={imgVideo} alt="Videos" />
                                    </div>
                                </div>
                            </div><div className="swiper-slide">
                                <div className="slide-cont">
                                    <div className="slide-texto">
                                        <h2>Endereço exclusivo</h2>
                                        <p>
                                            O Ooppah te dá um endereço exclusivo (slug) na web, como ooppah.com.br/seunome para você enviar a quem quiser!
                                        </p>
                                    </div>
                                    <div className="slide-image">
                                        <img src={imgEstatisticas} alt="Estatísticas" />
                                    </div>
                                </div>
                            </div><div className="swiper-slide">
                                <div className="slide-cont">
                                    <div className="slide-texto">
                                        <h2>Mensagens</h2>
                                        <p>
                                            uma caixa de mensagem exclusiva, para você receber mensagens diretas dos
                                            produtores de elenco que desejam entrar em contato diretamente com você.
                                        </p>
                                    </div>
                                    <div className="slide-image">
                                        <img src={imgMensagens} alt="Perfil" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        )
    }
}
    