import styled from "styled-components";

export const Container = styled.div`
    padding: 3px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 2px;

    background-color: ${props => props.backgroundColor};

    &.marginRight {
        margin-right: 10px;
    }

    &.marginLeft {
        margin-left: 10px
    }

    &.cursor-click{
        cursor: pointer;
    }
    
`;