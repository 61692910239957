import styled from 'styled-components';


export const Plan = styled.div`
    padding: 60px 0 0 0;
    text-align: center;
    vertical-align: middle;
    background-color: #f9f6f4;
    h2 {
        color: #616061;
        font-size: 42px;
        display: block;
        font-weight: 300;
    }
    @media (min-width: 1080px) {
        h2 {
            font-size: 62px;
        } 
    } 
    p {
        color: #616061;
        font-size: 14px;
    }

    .subtitle {
        color: #616061;
        font-size: 14px; 
        margin-bottom: 40px;
    }

`;
export const PlanBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    margin-bottom: 20px;
    margin: auto;
    @media (min-width: 1080px){
        margin-bottom: 0;
        flex-direction: row;
        width: 100%;
    }
`;

export const ContentPlan = styled.div`
    border: 1px solid #3b599a;
    border-radius: 5px;
    display: block;
    margin-bottom: 20px;
    margin-top: 5px;
    width: 100%;
    padding-top:15px;
    @media (min-width: 1080px){
        width: 30%;
    }

    .space {
        margin-bottom:15px;
    }
`;

export const PlanTop = styled.div`
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 10px;
    text-align: center;
    text-transform: uppercase;
    color: #3b599a;
`;

export const PricePlan = styled.div`
    font-size: 12px;
    
    text-align: center;
    text-transform: uppercase;
    color: #3b599a;
    div {
        display: flex;
        justify-content: center;
        span{
            font-size: 30px;
        }
        h3 {
            margin-bottom: 8px;
            align-self: flex-end;
        }
    }
`;

export const PlanDescription = styled.div`
    font-size: 12px;
    max-width: 80%;
    
    align: center;
    margin-left: auto;
    margin-right: auto;
`;


export const Li = styled.li`
    display: flex;
    margin-top: 5px;
    align-items: center;
    text-align: left;
    p {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const Border = styled.div`
    border-bottom: 1px solid;
    width: 100%;
    border-color: #F2E1E1E1;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
`;

export const Img = styled.img`
    width: 21px;
    height: 25px;
    margin-right: 5px;
`;

export const ButtonBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Button = styled.a`
    background-color: transparent;
    border-radius: 40px;
    border: 1px solid #3b599a;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom:20px;
    padding: 20px 35px;
    text-transform: uppercase;
    color: #3b599a;
    cursor: pointer;
    &:hover {
        background-color: #3b599a;
        color: white;
    }
`;

export const Combo04Price = styled.img`
    width: 100%;
    @media (min-width: 1080px){
        width: 45%;
    }
`;

export const Combo04Description = styled.img`
    width: 100%;
    @media (min-width: 1080px){
        width: 45%;
    }
`;

export const Combo04Content = styled.div`
    border: 1px solid #3b599a;
    border-radius: 5px;
    width:90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    @media (min-width: 1080px){
        width: 95%;
    }
`;