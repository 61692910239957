import React, { Component } from 'react'
import Swiper from 'swiper/dist/js/swiper.min.js';
import 'swiper/dist/css/swiper.min.css';
import axios from 'axios';

// Style
import './style.css'

// Images e Assets
import artista from '../../assets/artista.png'
import artistaPrev from '../../assets/artista-prev.png'
import artistaNext from '../../assets/artista-next.png'

export default class Artista extends Component {

    state = {
        slideItems: []
    }

    componentDidMount() {

        axios.get(`https://ooppah.com.br/api/artists/featured`)
			.then(res => {
                let dataSlide = res.data.data;
                let arr = []
				dataSlide.map((elem, index) => {
                    arr.push(
                        <div key={index} className="swiper-slide">
                            <div className="slide-cont">
                                <div className="avatar">
                                    <img src={elem.avatar} />
                                </div>
                                <div className="texto">
                                    <p>{elem.name}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
                this.setState({slideItems: arr})
                const artistaSwiper = new Swiper('.artista-slide', {
                    slidesPerView: 3,
                    slidesPerColumn: 3,
                    spaceBetween: 10,
                    navigation: {
                        nextEl: '.artista-next',
                        prevEl: '.artista-prev'
                    },
                    breakpoints:{
                        1080:{
                            slidesPerView: 1,
                        }
                    }
                });
			}
        )

    }
    
    render() {
        return (
            <div id="artista" className="artista-section">
                <div className="artista-topo">
                    <h2>Artista é movimento</h2>
                    <p>Conheça alguns artistas que saíram na frente e já se cadastraram no Ooppah.</p>
                </div>
                <div className="container">
                    <div className="artista-slide swiper-container">
                        <div className="swiper-wrapper">
                           {this.state.slideItems}
                        </div>
                    </div>
                    <div className="artista-slide-nav">
                        <div className="artista-prev"><img src={artistaPrev} /></div>
                        <div className="artista-next"><img src={artistaNext} /></div>
                    </div>
                </div>
            </div>
        )
    }
}
    