import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    background: black;
`;

export const Controls = styled.div`
    position: absolute;
    bottom: 35px;
    left: 25px;
    right: 25px;
    width: auto;
    max-width: 1230px;
    margin: 0 auto;
    min-height: 26px;
    z-index: 1;
    @media(min-width: 1080px) {
        bottom: 50px;
        a {
            float:right;
        }
    }

    @media(min-width: 1300px) {
        bottom: 50px;
        a {
            float:left;
        }
    }
`;