import styled from 'styled-components';

export const Container = styled.div`

    display: ${props => props.show ? 'block':'none'}

    .titulo-bloco {
        color: #3b599a;
        font-size: 14px;
        font-weight: 700;
        text-align: left;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

    .minor {
        .formulario-artista {
            .titulo-bloco {
                color: #e78724;
            }
        }
    }

    .linha {
        align-items: stretch;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        flex-flow: wrap;

        .input input {
            border: 1px solid #3b599a;
        }

        @media (min-width: 1080px){
            flex-flow: nowrap;
        }

        .w-1 {
            width: 100%;
            @media (min-width: 1080px){
                width: 8%;
            }
        }
            
        .w-2 {
            width: 100%;
            @media (min-width: 1080px){
                width: 18%;
            }
        }
            
        .w-3 {
            width: 100%;
            @media (min-width: 1080px){
                width: 28%;
            } 
        }
            
        .w-4 {
            width: 100%;
            @media (min-width: 1080px){
                width: 38%;
            } 
        }
           
        .w-5 {
            width: 100%;
            @media (min-width: 1080px){
                width: 48%;
            }
        }
            
        .w-6 {
            width: 100%;
            @media (min-width: 1080px){
                width: 58%;
            }   
        }
            
        .w-7 {
            width: 100%;
            @media (min-width: 1080px){
                width: 68%;
            }    
        }       
    }
    .central-button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
`;

export const Button = styled.button`
    cursor: pointer;
    background-color: #3b599a;
    border-radius: 40px;
    border: 1px solid #3b599a;
    font-size: 17px;
    padding: 5px 5px;
    text-transform: uppercase;
    color: #FFF;
    text-align: center;
    width: 20%;

    @media (min-width: 1080px){
        width: 20%;
    }

    :disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;


export const PlanTerms = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    max-width: 500px;
    align-items: center;
    margin-bottom: 15px;
    
    label {
        margin-left: 10px;
        color: #666;
    }

    a {
        color: #666;
        text-decoration: underline;
    }
`;

export const ContentButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;