import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from "../../formComponents/TextField/index";
import NumberOnlyField from "../../formComponents/NumberOnlyField/index";
import ZipCodeField from "../../formComponents/ZipCodeField/index";

const propTypes = {
    show: PropTypes.bool.isRequired
};

const defaultProps = {};

class Address extends Component {

    constructor(props) {
        super(props);

        this.addressNumberComponent = React.createRef();
    };

    state = {
        isValid: false,
        waiting: false,

        zipcode: this.props.zipcode,
        address: this.props.address,
        address_number: this.props.address_number,
        address_complement: this.props.address_complement,
        address_district: this.props.address_district,
        address_city: this.props.address_city,
        address_state: this.props.address_state,
    };

    requiredFields = () => {
        return {
            zipcode: 'CEP',
            address: 'Endereço',
            address_number: 'Número do endereço',
            address_district: 'Bairro',
            address_city: 'Cidade',
            address_state: 'Estado',
            address_complement: "Complemento",
        };
    };

    values = {};

    componentDidUpdate = () => {
        Object.keys(this.state).forEach((item) => {
            if (item !== 'waiting' && item !== 'isValid' && this.state[item] !== undefined) {
                this.values[item] = {
                    value: this.state[item],
                    isValid: true
                };
            }
        });
    };

    handleOnChange = (e, isValid) => {
        this.values[e.target.name] = {
            value: e.target.value,
            isValid: isValid
        };
    };

    /**
     * Called via Ref property.
     * Check if data is valid.
     */
    validate = () => {
        let invalidFields = {};
        let validFields = {};

        const object = Object.keys(this.requiredFields());

        object.forEach((k) => {
            if (this.values.hasOwnProperty(k) && this.values[k].isValid) {
                validFields[k] = this.requiredFields()[k];
            } else {
                invalidFields[k] = this.requiredFields()[k];
            }
        });

        return {
            isValid: Object.keys(validFields).length === object.length,
            fields: {
                valid: validFields,
                invalid: invalidFields
            },
            values: this.values
        };
    };

    handleZipCodeOnBlur = (e, isValid) => {
        this.setState({
            waiting: true
        });

        fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    waiting: false,
                    address: data.logradouro,
                    address_district: data.bairro,
                    address_city: data.localidade,
                    address_state: data.uf
                },
                    // () => this.addressNumberComponent.current.focus()
                );
            });
    };

    render() {

        const style = {
            display: this.props.show ? '' : 'none'
        };

        return (
            <div className="formulario-artista" style={style}>
                <div className="titulo-bloco">Endereço</div>
                <div className="linha">
                    <div className="w-2">
                        <ZipCodeField
                            label="CEP"
                            name="zipcode"
                            required={true}
                            value={this.state.zipcode}
                            isValid={this.state.isValid}
                            waiting={this.state.waiting}
                            onChange={this.handleOnChange}
                        />
                    </div>

                    <div className="w-6">
                        <TextField
                            type="text"
                            label="Logradouro"
                            name="address"
                            required={true}
                            value={this.state.address}
                            onChange={this.handleOnChange}
                        />
                    </div>

                    <div className="w-2">
                        <NumberOnlyField
                            ref={this.addressNumberComponent}
                            name="address_number"
                            label="Número (somente números)"
                            required={true}
                            value={this.state.address_number}
                            onChange={this.handleOnChange}
                        />
                    </div>
                </div>

                <div className="linha">
                    <div className="w-3">
                        <TextField
                            type="text"
                            label="Complemento"
                            name="address_complement"
                            value={this.state.address_complement}
                            required={true}
                            maxLength={40}
                            onChange={this.handleOnChange}
                        />
                    </div>

                    <div className="w-3">
                        <TextField
                            type="text"
                            label="Bairro"
                            name="address_district"
                            required={true}
                            value={this.state.address_district}
                            onChange={this.handleOnChange}
                        />
                    </div>

                    <div className="w-3">
                        <TextField
                            type="text"
                            label="Cidade"
                            name="address_city"
                            required={true}
                            value={this.state.address_city}
                            onChange={this.handleOnChange}
                        />
                    </div>

                    <div className="w-1">
                        <TextField
                            type="text"
                            label="UF"
                            name="address_state"
                            required={true}
                            value={this.state.address_state}
                            maxLength={2}
                            pipe={(v) => v.toUpperCase()}
                            onChange={this.handleOnChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

Address.propTypes = propTypes;
Address.defaultProps = defaultProps;

export default Address;
