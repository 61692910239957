import styled from 'styled-components';
import NavContainer, { NavItem as NavItemComponent } from "../Nav";

export const Container = styled.div`
    align-items: center;
    display: flex;
    max-width: 1230px;
    padding: 23px 5px;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 auto;
    z-index: 5;
    order: 1;
    @media (min-width: 1080px) {
        padding: 23px 30px;
        order: 2;
        top: 0;
        right: 0;
        left: 0;
        position: absolute;
        flex-flow: row nowrap;
        background-color: transparent;
    }
    .logo {
        margin-bottom: 30px;
        @media(min-width: 1080px) {
            margin-bottom: 5px;
        }
    }
    .burger-menu {
        margin-bottom: 32px;
    }
`;

export const Navigation = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    @media (min-width: 1080px) {
        flex-grow: 1
        justify-content: space-between
        margin-left: 20px
    }
`;

export const Nav = styled(NavContainer)`
    align-items: center;
    display: none;
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    justify-content: flex-start;
    @media (min-width: 1080px) {
        flex: 2;
        margin-right: 20px;
        position: relative;
        top: 0;
        background-color: transparent;
        padding: 0;
        width: auto;
        display: flex;
    }
    &.open {
        display: block;
        padding-top: 85px;
        padding-bottom: 30px;
        top: 85px;
        z-index: 3;
    }
    li {
        margin-right: 30px;
        position: relative;
        padding: 10px;
        @media (min-width: 1080px) {
            padding: 0
        }
        &:after {
            background-color: transparent
            content: ''
            display: block
            height: 2px
            width: 30%
            transition: all 0.2s 0s linear
        }
        a {
            color: #3b599a
            font-size: 14px
            padding:2px 4px
            border-radius: 4px
            text-transform: uppercase
            transition: all 0.2s 0s linear
            @media (min-width: 1080px) {
                color: white;
                text-shadow: 1px 1px 3px #333;
            }
        &:hover {
            a
                text-shadow: none
                @media(min-width: 1080px) {
                    background: #e78724;
                    color: #fff;
                }
            }
        }
    }
`;

export const NavItem = styled(NavItemComponent)`
`;
// TODO: remover o 'margin-left: 35%' quando a ação de cadastro voltar ao normal.
export const ActionButtons = styled.div`
    flex: 1;
    display: flex;
    width: 100%;
    position: relative;
    margin-top: 5px;
    align-items: center;
    a {
        @media(min-width: 1080px) {
            border-radius: 100px;
        }
    }
`;

export const RegisterButton = styled.a`
    flex: 2;
    border: 1px solid #e78724;
    background: #e78724;
    margin-right: 10px;
    color: #fff;
    text-align: center;
    padding: 10px 0px 10px 0px;
    text-transform: uppercase;
    z-index: 4;
`;

export const LoginButton = styled.a`
    flex: 1;
    border: 1px solid #fff;
    background: #fff;
    color: #e78724;
    text-align: center;
    padding: 10px 2px 10px 2px;
    text-transform: uppercase;
    z-index: 4;
`;