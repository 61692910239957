import styled, { keyframes } from 'styled-components';


const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const Container = styled.div`

`;

export const Spinner = styled.div`
    animation: ${rotate} 2s linear infinite;
`;

export const Icon = styled.img`
    width: 50px;
    height: 50px;
`;

