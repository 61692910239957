import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from "../TextField/index";
import CpfValidator from '../../../Utils/CpfValidator';

const propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    feedbackText: PropTypes.string,
    required: PropTypes.bool
};

const defaultProps = {
    label: 'CPF',
    name: 'document',
    required: false,
    feedbackText: 'CPF inválido',
    mask: '11111111111',
    onChange: () => {}
};

class CpfField extends Component {
    state = {
        waiting: false,
        isValid: false,
        feedbackText: this.props.feedbackText,
        cpfRepeat: false,
    };

    /**
     * Validator method.
     *
     * @param number
     */
    isValid = (number) => CpfValidator.isValid(number);

    handleOnChange = (e, isValid) => {
        e.persist();

        this.setState({
            isValid: this.isValid(e.target.value)
        }, () => this.props.onChange(e, isValid));
    };

    handleOnBlur = (e, isValid) => {

        e.persist();

        if (! this.state.isValid) {
            return;
        }

        const value = e.target.value;

        const handleError = (response) => {
            if (! response.ok) {
                throw Error('An error occur:', response.statusText);
            }
            return response;
        };

        const setStateByResponse = (isValid, feedbackText, cpfRepetido = false) => {
            this.setState({
                isValid: isValid,
                feedbackText: feedbackText,
                waiting: false,
                cpfRepeat: cpfRepetido
            }, () => {
                this.props.onChange(e, this.state.isValid);
            });
        };

        this.setState({waiting: true}, () => {
            fetch(`${process.env.REACT_APP_API_REGISTER}/subscribers/check-if-document-exists`, {
                method: 'post',
                body: JSON.stringify({document: value}),
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(handleError)
                .then(response => response.json())
                .then(json => {
                    if (json.success === true && json.data.exists === true) {
                        setStateByResponse(false, 'CPF já cadastrado.', true);
                    } else {
                        setStateByResponse(true, '');
                    }
                })
                .catch(error => {
                    setStateByResponse(false, 'Erro interno do servidor. Por favor, tente novamente mais tarde.')
                });
        });
    };

    render() {
        return (
            <>
            <TextField
                type="tel"
                label={this.props.label}
                name={this.props.name}
                isValid={this.state.isValid}
                feedbackText={this.state.feedbackText}
                onChange={this.handleOnChange}
                onBlur={this.handleOnBlur}
                mask={this.props.mask}
                waiting={this.state.waiting}
                required={this.props.required}
            />
            {(this.state.cpfRepeat) &&
                <span style={{color: 'red'}}>Talvez você ja tenha uma conta, <a target="_blank" href='https://account.ooppah.com'>clique aqui</a> para gerencia-la</span>
            }
            
            </>
        );
    }
}

CpfField.propTypes = propTypes;
CpfField.defaultProps = defaultProps;

export default CpfField;
