import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 3;

    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    background-color: white;
    
    border-top: 13px solid #385799;
    border-radius: 10px;
    
    padding: 15px 15px;

    color: #3A4659;
    
`;

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    box-sizing: border-box;
    z-index: 2;
    max-width: 95%;
`;

export const CloseWrapper = styled.div`
    width: 100%;

    margin-bottom: 3px;
    display: flex;
    justify-content: flex-end;
`;