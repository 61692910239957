import React from 'react';
import { Container, Icon } from "./style";
import MinusIcon from '../../../assets/menos.svg';


const Item = ({item}) => {

    return (
        <Container isActivated = {item.isActivated}>
            <Icon src={ item.isActivated ? item.icon : MinusIcon}/>
            <span dangerouslySetInnerHTML={{__html: item.name}}></span>
        </Container>
    );
}

export default Item;