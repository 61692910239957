import React, {Component} from 'react'
import PropTypes from 'prop-types';

import './style.css'

const propTypes = {
    /**
     * Show or hide the component.
     */
    show: PropTypes.bool
};

const defaultProps = {
    show: false
};

const Spinner = ({show}) => {

    const style = {
        display: show ? 'inline-block' : 'none'
    };

    return (
        <div className="spinner" style={style} >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;

export default Spinner;