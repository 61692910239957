import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';

const propTypes = {};

const defaultProps = {};

const PlanPlaceholder = (props) => {

    const content = <ContentLoader
        height={413}
        width={351}
        speed={1}
        primaryColor="#e0e0e0"
        secondaryColor="#c8c8c8"
        {...props}
    >
        <rect x="123" y="43.5" rx="0" ry="0" width="108.04" height="8" />
        <rect x="110" y="58" rx="0" ry="0" width="132.89" height="8" />
        <rect x="415" y="180" rx="3" ry="3" width="201" height="6.4" />
        <rect x="124" y="108" rx="0" ry="0" width="107.35" height="11.06" />


        <rect x="116" y="126" rx="0" ry="0" width="121.76" height="21.24" />
        <rect x="93" y="205" rx="0" ry="0" width="165.6" height="8" />
        <rect x="85" y="221" rx="0" ry="0" width="183.22" height="8" />
        <rect x="85" y="237" rx="0" ry="0" width="183.22" height="8" />
        <rect x="120" y="253" rx="0" ry="0" width="114.51" height="8" />
        <rect x="95" y="307" rx="0" ry="0" width="165" height="57" />
    </ContentLoader>;

    return (
        <label className="bloco-plano" style={{padding: 0, borderColor: '#c8c8c8'}}>
            {content}
        </label>
    );
};

PlanPlaceholder.propTypes = propTypes;
PlanPlaceholder.defaultProps = defaultProps;

export default PlanPlaceholder;
