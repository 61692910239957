import styled, { css } from 'styled-components';

export const CardDefault = styled.div`
    max-width: 800px;
    width: 100%;
    margin-bottom: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: #c9c9c9;
    border-radius: 10px;
    padding: 3px 7px;
    box-shadow: 3px 2px 2px black;

`;