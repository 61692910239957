import React from 'react';
import { Container, LogoImage } from "./styles";

export default function LogoButton(props) {
    const {
        className,
        link,
        title,
        alt,
        logo,
        width,
        target,
    } = props;

    return (
        <Container className={className}>
            <a target={target} href={link} title={title} style = {{display:"flex", alignItems:"center"}}>
                 <LogoImage src={logo} alt={alt} style ={{width:width+'px'}}/>
                {props.children}
            </a>
        </Container>
    )
}