import styled from 'styled-components';

export const Container = styled.div`
    display: block;
    margin-left: 10px;
    @media(min-width: 1080px) {
        margin-bottom: 0;
        margin-left: 0;

        a {
            color: white !important;
            text-shadow: 1px 1px 3px #333;
        }
    }
    
    a{
        text-decoration: none;
        color: #3b599a;
    }
`;

export const LogoImage = styled.img.attrs({
})`
    width: 30px;
    padding-bottom:10px;
`;