import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    border-width: 1px;
    border-radius: 2px;
    border-style: solid;
    border-color: #e6e3e3;
    padding: 10px;
`;

export const Photo = styled.img`
    width: 80px;
`;

export const Name = styled.span`
    font-weight: bold;
    margin-top: 10px;
    display: inline-block;
`;

export const Rating = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

export const Declaration = styled.span`
    display: inline-block;
    box-sizing: border-box;

    padding-right: 10px;
`;

export const Star = styled.img`
    width: 12px;
`;