import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 5px;
`;

export const Ul = styled.ul`
    display:flex;
`;

export const PageButton = styled.button`
    width: 25px;
    padding:1px;
    background-color: #e78724;
    cursor:pointer
    border-color: white;
    color: white;
`;

export const PrevNextButton = styled.button`
    background-color: #e78724;
    cursor:pointer;
    border-color: white;
    padding: 2px
    
`;

export const ArrowImg = styled.img`
    width: 16px;
    height: 16px;
`;

export const ThreeDots = styled.div`
    font-size: 25px;
    line-height: 20px;
    margin-left: 3px;
    margin-right: 3px;
`;