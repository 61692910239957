import React, { useState } from "react";
import Card from "../Card";
import { AnswerTextArea, Header, PreAnswerArea, PreAnswerGroup } from '../style';


const SingleResponse = ({question, updateAnswer, otherId}) => {

    const [responseId, setResponseId] = useState("");
    const [textResponse, setTextresponse] = useState("");

    const onAnswerChange = (event) => {
        if(event.target.name == "textarea"){
            let answer = {
                id: question.id,
                type: question.type,
                answer: responseId,
                text_answer: event.target.value,
            }
            updateAnswer(question, answer);
        }else {
            let answer = {
                id: question.id,
                type: question.type,
                answer: event.target.value,
                text_answer: textResponse,
            }
            setResponseId(event.target.value);
            updateAnswer(question, answer);
        }
        
    }

    const onTextChange = (event) => {
        setTextresponse(event.target.value);
    }



    return (
        <Card>
            <Header>
                {question.text}
            </Header>
            
            <PreAnswerArea onChange={onAnswerChange}>
                {//Se existir conteudo dentro de preAnswerOrder, executa este método
                    question.preAnswerOrders.length > 0 && 
                    question.preAnswerOrders.map((preAnswerOrder) => {
                        let preAnswer = question.preAnswers.find(preAnswer => {
                            return preAnswer.id === preAnswerOrder.pre_answer_id;
                        })
                        
                        return(
                            <PreAnswerGroup key = {preAnswer.id}>
                                <input type="radio" name={"preAnswer"+question.id} value={preAnswer.id} />
                                <p>{preAnswer.text}</p>
                            </PreAnswerGroup>
                        );
                        
                    })
                }
                {// Se não existir conteudo de preAnswerOrder, executa este
                    question.preAnswerOrders.length === 0 && 
                    question.preAnswers.map((preAnswer) => {
                        return(
                            <PreAnswerGroup key = {preAnswer.id}>
                                <input type="radio" name={"preAnswer"+question.id} value={preAnswer.id} />
                                <p>{preAnswer.text}</p>
                            </PreAnswerGroup>
                        );
                    })
                }
                {
                    responseId == otherId &&
                    <AnswerTextArea name = "textarea" placeholder= "Escreva Aqui" value = {textResponse} onChange={onTextChange}/>
                }
            </PreAnswerArea>
            
        </Card>
    );
}

export default SingleResponse;