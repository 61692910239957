import styled, { css } from 'styled-components';

export const FloatButton = styled.a`
    position:fixed;
    bottom: 0px;
    right: 0px;
    z-index: 100;
    width: 70px;
    height: 70px;
    cursor:pointer;
    border-radius: 50%;
    background-color: transparent
    transition: all 0.2s 0s linear
    &:hover {
        background-color: #e78724;
    }
`;