import styled from "styled-components";
export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
`;
export const RegisterButton = styled.a`
  border: 1px solid #e78724;
  background: #e78724;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-transform: uppercase;
  z-index: 2;
  cursor: pointer;
  margin-right: 10px;
  width: 13rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  
  @media (min-width: 1080px) {
    border-radius: 100px;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding-top: 5px;
  gap: 8px;
  width: 10em;
  z-index: 2;
  background-color: #e78724;
  top: calc(100% + 5px);
  box-shadow: 0 -2px 7px rgba(0, 0, 0, 1);
  border-radius: 10px;
  overflow: hidden;
`;
export const LinkButton = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #fff;
  margin: 16px 2px;
  font-size: 12px;
  cursor: pointer;
  transition: transform 0.5s ease;
  span {
    padding-left: 5px;
  }
  :hover {
    padding-left: 12px;
    transform: scale(1.1);
    font-weight: 700;
  }
`;