import React, { useState } from 'react';
import { Container, Controls } from './styles';
import {SoundOn, SoundOff} from "../Icons";

export default function Video(props) {
    const {
        autoPlay = true,
        playsInline = true,
        loop = true,
        preload = true,
        className,
        src,
        type="video/mp4"
    } = props;

    const [muted, setMuted] = useState(true);

    function toggleSoundOn() {
        setMuted(!muted);
    }

    return (
        <Container className={ className }>
            <a href="https://www.store.ooppah.com/" target="_blank">
            <video
                autoPlay={autoPlay}
                playsInline={playsInline}
                loop={loop}
                muted={muted}
                preload={preload.toString()}
                onClick={toggleSoundOn}
            >
                <source src={src} type={type} />
            </video>
            </a>

            <Controls>
                <a href="javascript:" onClick={toggleSoundOn}>{ muted ? <SoundOff /> : <SoundOn />}</a>
            </Controls>
        </Container>
    );
}

