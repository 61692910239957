import React from "react";
import {Container} from "./style.js";
import imgClub from './../../assets/Ooppah_Club_Parceiros.png';
import { Link } from "react-router-dom";

const Club = () => {

    

    return (
        <Container>
            <Link to="/clube/#top"> 
                <img src={imgClub} alt="Clube ooppah" />
            </Link>
        </Container>
    );
}

export default Club;